import React, { FC } from "react";

const SunyIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="278.683"
      height="164.933"
      viewBox="0 0 200 164.933"
    >
      <path
        id="Path_1864"
        data-name="Path 1864"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(27.218 61.951) rotate(-21)"
        fill="#51ced2"
      />
      <g id="happy" transform="translate(69.698 83.414) rotate(-25)">
        <path
          id="Path_1875"
          data-name="Path 1875"
          d="M3.291,1.241a1.674,1.674,0,0,0-3.234.866,17.294,17.294,0,0,0,16.68,12.8,17.293,17.293,0,0,0,16.68-12.8,1.674,1.674,0,0,0-3.234-.866A13.941,13.941,0,0,1,16.738,11.558,13.94,13.94,0,0,1,3.291,1.241Zm0,0"
          transform="translate(4.206 17.634)"
          fill="#51ced2"
        />
        <path
          id="Path_1876"
          data-name="Path 1876"
          d="M3.29,6.619a4.413,4.413,0,0,1,8.526,0,1.674,1.674,0,0,0,3.233-.866,7.76,7.76,0,0,0-14.992,0,1.674,1.674,0,1,0,3.233.866Zm0,0"
          transform="translate(26.78 0)"
          fill="#51ced2"
        />
        <path
          id="Path_1877"
          data-name="Path 1877"
          d="M7.553,0a7.771,7.771,0,0,0-7.5,5.753,1.674,1.674,0,0,0,3.233.866,4.413,4.413,0,0,1,8.526,0,1.674,1.674,0,0,0,3.233-.866A7.772,7.772,0,0,0,7.553,0Zm0,0"
          transform="translate(0 0)"
          fill="#51ced2"
        />
      </g>
      <g
        id="molecular"
        transform="matrix(0.883, 0.469, -0.469, 0.883, 38.521, 10.813)"
      >
        <path
          id="Path_1894"
          data-name="Path 1894"
          d="M25.755,23.35V12.057a6.124,6.124,0,1,0-3.047,0V23.35a10.224,10.224,0,0,0-7.426,5.2l-6.7-5.21a4.425,4.425,0,1,0-1.948,2.345l7.575,5.889a10.152,10.152,0,0,0,1.821,7.909L7.463,48.047A1.524,1.524,0,0,0,9.618,50.2l8.565-8.565a10.146,10.146,0,0,0,4.525,1.88V54.835a4.417,4.417,0,1,0,3.047,0V43.517a10.2,10.2,0,0,0,0-20.167ZM4.417,23.236a1.37,1.37,0,1,1,1.37-1.37A1.372,1.372,0,0,1,4.417,23.236ZM21.154,6.124A3.077,3.077,0,1,1,24.231,9.2,3.08,3.08,0,0,1,21.154,6.124Zm3.077,54.227a1.37,1.37,0,0,1-.275-2.713,1.461,1.461,0,0,0,.551,0,1.37,1.37,0,0,1-.275,2.713Zm0-19.768a7.147,7.147,0,0,1-6.862-9.155c.01-.031.019-.063.027-.095a7.15,7.15,0,1,1,6.835,9.25Z"
          transform="translate(0 0)"
          fill="#51ced2"
        />
      </g>
      <g
        id="molecular-2"
        data-name="molecular"
        transform="translate(58.174 40.169) rotate(-68)"
      >
        <path
          id="Path_1894-2"
          data-name="Path 1894"
          d="M20.937,18.981V9.8a4.979,4.979,0,1,0-2.477,0v9.18a8.311,8.311,0,0,0-6.037,4.227L6.975,18.973a3.6,3.6,0,1,0-1.584,1.906l6.158,4.787a8.252,8.252,0,0,0,1.48,6.429L6.067,39.058a1.239,1.239,0,0,0,1.752,1.751l6.963-6.962a8.248,8.248,0,0,0,3.679,1.528v9.2a3.591,3.591,0,1,0,2.477,0v-9.2a8.29,8.29,0,0,0,0-16.394ZM3.591,18.888A1.114,1.114,0,1,1,4.7,17.775,1.115,1.115,0,0,1,3.591,18.888ZM17.2,4.978a2.5,2.5,0,1,1,2.5,2.5A2.5,2.5,0,0,1,17.2,4.978ZM19.7,49.06a1.114,1.114,0,0,1-.224-2.205,1.187,1.187,0,0,0,.448,0A1.114,1.114,0,0,1,19.7,49.06Zm0-16.069a5.81,5.81,0,0,1-5.578-7.442c.008-.026.016-.051.022-.077A5.813,5.813,0,1,1,19.7,32.991Z"
          transform="translate(0 0)"
          fill="#51ced2"
        />
      </g>
      <path
        id="Path_1897"
        data-name="Path 1897"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(112.315 33.224) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1898"
        data-name="Path 1898"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(30.463 20.741) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1899"
        data-name="Path 1899"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(82.93 7.462) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1900"
        data-name="Path 1900"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(50.826 9.759) rotate(-21)"
        fill="#51ced2"
      />
      <g id="happy-2" data-name="happy" transform="translate(29.712 44.778)">
        <path
          id="Path_1901"
          data-name="Path 1901"
          d="M100.421,15.544a48.166,48.166,0,1,1-96.331,0c.2-4.049-4.295-4.308-4.082,0a51.906,51.906,0,0,0,15.3,36.945,52.247,52.247,0,0,0,73.889,0,51.907,51.907,0,0,0,15.3-36.945C104.5,1.589,100.421-11.014,100.421,15.544Z"
          transform="matrix(0.921, -0.391, 0.391, 0.921, 0, 40.832)"
          fill="#51ced2"
          stroke="#51ced2"
          strokeWidth="1"
        />
      </g>
      <path
        id="Path_1902"
        data-name="Path 1902"
        d="M4.287,0A4.287,4.287,0,1,0,8.574,4.287,4.292,4.292,0,0,0,4.287,0Zm0,6.441A2.154,2.154,0,1,1,6.441,4.287,2.156,2.156,0,0,1,4.287,6.441Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 143.733, 71.282)"
        fill="#51ced2"
      />
      <path
        id="Path_1903"
        data-name="Path 1903"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(32.437 86.839) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1904"
        data-name="Path 1904"
        d="M20.4,10.2H5.1A5.1,5.1,0,0,1,5.1,0H20.4a5.1,5.1,0,0,1,0,10.2Z"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 130.387, 43.733)"
        fill="#51ced2"
        stroke="#fff"
        strokeWidth="4"
      />
      <path
        id="Path_1905"
        data-name="Path 1905"
        d="M6.124,0a6.124,6.124,0,1,0,6.124,6.124A6.131,6.131,0,0,0,6.124,0Zm0,9.2A3.077,3.077,0,1,1,9.2,6.124,3.081,3.081,0,0,1,6.124,9.2Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 68.527, 17.319)"
        fill="#51ced2"
      />
      <path
        id="Path_1906"
        data-name="Path 1906"
        d="M4.512,0A4.512,4.512,0,1,0,9.024,4.512,4.517,4.517,0,0,0,4.512,0Zm0,6.779A2.267,2.267,0,1,1,6.779,4.512,2.27,2.27,0,0,1,4.512,6.779Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 18.746, 99.604)"
        fill="#51ced2"
      />
      <path
        id="Path_1907"
        data-name="Path 1907"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(13.201 63.671) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1908"
        data-name="Path 1908"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(49.885 5.353) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1909"
        data-name="Path 1909"
        d="M6.124,0a6.124,6.124,0,1,0,6.124,6.124A6.131,6.131,0,0,0,6.124,0Zm0,9.2A3.077,3.077,0,1,1,9.2,6.124,3.081,3.081,0,0,1,6.124,9.2Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 68.527, 17.319)"
        fill="#51ced2"
      />
      <path
        id="Path_1910"
        data-name="Path 1910"
        d="M4.512,0A4.512,4.512,0,1,0,9.024,4.512,4.517,4.517,0,0,0,4.512,0Zm0,6.779A2.267,2.267,0,1,1,6.779,4.512,2.27,2.27,0,0,1,4.512,6.779Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 100.087, 24.406)"
        fill="#51ced2"
      />
      <path
        id="Path_1911"
        data-name="Path 1911"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(58.529 35.681) rotate(-21)"
        fill="#51ced2"
      />
      <path
        id="Path_1912"
        data-name="Path 1912"
        d="M1.673,3.347A1.674,1.674,0,1,0,.491.49h0A1.673,1.673,0,0,0,1.673,3.347Zm0,0"
        transform="translate(97.858 52.8) rotate(-25)"
        fill="#51ced2"
      />
      <path
        id="Path_1913"
        data-name="Path 1913"
        d="M4.512,0A4.512,4.512,0,1,0,9.024,4.512,4.517,4.517,0,0,0,4.512,0Zm0,6.779A2.267,2.267,0,1,1,6.779,4.512,2.27,2.27,0,0,1,4.512,6.779Z"
        transform="matrix(-0.695, -0.719, 0.719, -0.695, 60.644, 63.912)"
        fill="#51ced2"
      />
      <path
        id="Path_1914"
        data-name="Path 1914"
        d="M1.673,3.347A1.674,1.674,0,1,0,.491.49h0A1.673,1.673,0,0,0,1.673,3.347Zm0,0"
        transform="translate(97.858 52.8) rotate(-25)"
        fill="#51ced2"
      />
      <path
        id="Path_1915"
        data-name="Path 1915"
        d="M1.673,3.347A1.674,1.674,0,1,0,.491.49h0A1.673,1.673,0,0,0,1.673,3.347Zm0,0"
        transform="translate(28.317 2.947) rotate(-25)"
        fill="#51ced2"
      />
      <text
        id="Suny_"
        data-name="Suny  "
        transform="translate(161.683 60.919)"
        fill="#51ced2"
        fontSize="30"
        fontFamily="SegoeUI-Bold, Segoe UI"
        fontWeight="700"
      >
        <tspan x="0" y="32" xmlSpace="preserve">
          Suny™
        </tspan>
      </text>
      <path
        id="Path_1855"
        data-name="Path 1855"
        d="M20.4,10.2H5.1A5.1,5.1,0,0,1,5.1,0H20.4a5.1,5.1,0,0,1,0,10.2Z"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 2.674, 110.111)"
        fill="#51ced2"
        stroke="#fff"
        strokeWidth="4"
      />
      <path
        id="Path_1857"
        data-name="Path 1857"
        d="M5.106,21.021A5.106,5.106,0,0,1,1.5,12.314L12.32,1.494a5.1,5.1,0,1,1,7.213,7.213L8.712,19.527A5.085,5.085,0,0,1,5.106,21.021Z"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 41.257, 143.327)"
        fill="#51ced2"
        stroke="#fff"
        strokeWidth="4"
      />
      <path
        id="Path_1858"
        data-name="Path 1858"
        d="M10.157,15.511a3.036,3.036,0,0,1-2.3-1.1L.953,6.425a4.192,4.192,0,0,1,0-5.322,2.952,2.952,0,0,1,4.6,0l6.9,7.984C14.513,11.463,13.029,15.511,10.157,15.511Z"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 137.031, 99.043)"
        fill="#51ced2"
        stroke="#fff"
        strokeWidth="2"
      />
      <path
        id="Path_1853"
        data-name="Path 1853"
        d="M5.1,25.5A5.1,5.1,0,0,1,0,20.4V5.1a5.1,5.1,0,1,1,10.2,0V20.4A5.1,5.1,0,0,1,5.1,25.5Z"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 109.026, 137.959)"
        fill="#51ced2"
        stroke="#fff"
        strokeWidth="4"
      />
      <path
        id="Path_1916"
        data-name="Path 1916"
        d="M2,4.006A2,2,0,1,0,.587.587h0A2,2,0,0,0,2,4.006Zm0,0"
        transform="translate(145.44 55.051) rotate(-21)"
        fill="#51ced2"
      />
    </svg>
  );
};

export default SunyIcon;
