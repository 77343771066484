import React, { FC } from "react";

const Target: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="340.24"
      height="340.24"
      viewBox="0 0 340.24 340.24"
    >
      <rect
        id="Rectangle_255"
        data-name="Rectangle 255"
        width="340"
        height="85"
        transform="translate(0.24 254.24)"
        fill="#39a6ac"
      />
      <g id="Group_1076" data-name="Group 1076">
        <g id="Group_234" data-name="Group 234" transform="translate(0 0)">
          <g
            id="Group_225"
            data-name="Group 225"
            transform="translate(103.031 103.031)"
          >
            <g id="Group_224" data-name="Group 224">
              <path
                id="Path_384"
                data-name="Path 384"
                d="M286.956,950.122a67.089,67.089,0,1,1,67.089-67.089A67.164,67.164,0,0,1,286.956,950.122Zm0-116.259a49.17,49.17,0,1,0,49.171,49.17A49.226,49.226,0,0,0,286.956,833.863Z"
                transform="translate(-219.868 -815.944)"
                fill="#426788"
              />
            </g>
          </g>
          <g
            id="Group_227"
            data-name="Group 227"
            transform="translate(138.693 138.693)"
          >
            <g id="Group_226" data-name="Group 226">
              <path
                id="Path_385"
                data-name="Path 385"
                d="M291.1,918.6a31.427,31.427,0,1,1,31.428-31.427A31.463,31.463,0,0,1,291.1,918.6Zm0-44.936a13.508,13.508,0,1,0,13.509,13.508A13.524,13.524,0,0,0,291.1,873.667Z"
                transform="translate(-259.672 -855.748)"
                fill="#426788"
              />
            </g>
          </g>
          <g
            id="Group_229"
            data-name="Group 229"
            transform="translate(68.8 68.8)"
          >
            <g id="Group_228" data-name="Group 228">
              <path
                id="Path_386"
                data-name="Path 386"
                d="M282.98,980.377A101.32,101.32,0,1,1,384.3,879.056,101.435,101.435,0,0,1,282.98,980.377Zm0-184.721a83.4,83.4,0,1,0,83.4,83.4A83.5,83.5,0,0,0,282.98,795.656Z"
                transform="translate(-181.66 -777.737)"
                fill="#426788"
              />
            </g>
          </g>
          <g
            id="Group_231"
            data-name="Group 231"
            transform="translate(33.571 33.571)"
          >
            <g id="Group_230" data-name="Group 230">
              <path
                id="Path_387"
                data-name="Path 387"
                d="M278.888,1011.514c-75.293,0-136.549-61.256-136.549-136.55s61.256-136.549,136.549-136.549,136.55,61.256,136.55,136.549S354.182,1011.514,278.888,1011.514Zm0-255.18a118.631,118.631,0,1,0,118.631,118.63A118.765,118.765,0,0,0,278.888,756.334Z"
                transform="translate(-142.339 -738.415)"
                fill="#426788"
              />
            </g>
          </g>
          <g id="Group_233" data-name="Group 233">
            <g id="Group_232" data-name="Group 232">
              <path
                id="Path_388"
                data-name="Path 388"
                d="M274.989,1041.185c-93.8,0-170.12-76.316-170.12-170.12s76.316-170.12,170.12-170.12,170.121,76.315,170.121,170.12S368.794,1041.185,274.989,1041.185Zm0-322.322c-83.923,0-152.2,68.277-152.2,152.2s68.278,152.2,152.2,152.2,152.2-68.278,152.2-152.2S358.913,718.864,274.989,718.864Z"
                transform="translate(-104.869 -700.945)"
                fill="#426788"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Target;
