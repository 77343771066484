import React, { FC } from "react";

const MegaphoneIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.232"
      height="89"
      viewBox="0 0 97.232 89"
    >
      <g id="megaphone" transform="translate(0 -21.675)">
        <path
          id="Path_1918"
          data-name="Path 1918"
          d="M430.337,297.612a2.849,2.849,0,1,0-4.029,4.028l5.746,5.746a2.849,2.849,0,1,0,4.028-4.028Z"
          transform="translate(-344.674 -222.859)"
          fill="#51ced2"
        />
        <path
          id="Path_1919"
          data-name="Path 1919"
          d="M428.323,128.222a2.841,2.841,0,0,0,2.014-.834l5.746-5.746a2.849,2.849,0,1,0-4.028-4.028l-5.746,5.746a2.849,2.849,0,0,0,2.014,4.863Z"
          transform="translate(-344.674 -77.043)"
          fill="#51ced2"
        />
        <path
          id="Path_1920"
          data-name="Path 1920"
          d="M450.182,221.907h-8.127a2.849,2.849,0,0,0,0,5.7h8.127a2.849,2.849,0,1,0,0-5.7Z"
          transform="translate(-355.799 -162.207)"
          fill="#51ced2"
        />
        <path
          id="Path_1921"
          data-name="Path 1921"
          d="M75.82,22.038a2.849,2.849,0,0,0-2.88.056L40.317,42.073H25.834a11.131,11.131,0,0,0-8.294,3.041,11.49,11.49,0,0,0-2.114,2.7H8.258A8.268,8.268,0,0,0,0,56.072V69.026a8.268,8.268,0,0,0,8.258,8.258h7.153A12.417,12.417,0,0,0,20.4,82.16L10.47,99.35a2.848,2.848,0,0,0,1.073,3.909l12.568,7.051a2.847,2.847,0,0,0,3.861-1.06L42.144,84.7l30.828,18.32a2.849,2.849,0,0,0,4.3-2.449V24.524a2.849,2.849,0,0,0-1.456-2.485ZM8.258,71.587A2.564,2.564,0,0,1,5.7,69.026V56.072a2.564,2.564,0,0,1,2.561-2.561H13.89c0,.043,0,.085,0,.127V71.46c0,.043,0,.085,0,.127ZM19.584,53.638a6.018,6.018,0,0,1,1.861-4.375,5.488,5.488,0,0,1,4.137-1.5c.058,0,12.69.005,12.69.005V77.932H25.847a6.622,6.622,0,0,1-6.263-6.472Zm4.862,50.326-7.6-4.263L25.958,83.92a2.854,2.854,0,0,0,.145-.291H36.186Zm47.133-8.4L43.969,79.16V46.518l27.61-16.909Z"
          transform="translate(0 0)"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default MegaphoneIcon;
