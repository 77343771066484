import React, { FC } from "react";

const FileIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="131.426"
      height="111.246"
      viewBox="0 0 131.426 111.246"
    >
      <g id="layout" transform="translate(0 -39)">
        <path
          id="Path_1922"
          data-name="Path 1922"
          d="M68.3,147.659a2.587,2.587,0,1,1-2.587-2.587A2.59,2.59,0,0,1,68.3,147.659ZM20.6,84.016a2.587,2.587,0,0,0,0,5.174h5.271v25.871a2.587,2.587,0,0,0,5.174,0V89.19h5.271a2.587,2.587,0,1,0,0-5.174Zm89.611-20.18A10.866,10.866,0,1,1,99.345,74.7,10.878,10.878,0,0,1,110.211,63.836ZM104.52,74.7a5.692,5.692,0,1,0,5.692-5.692A5.7,5.7,0,0,0,104.52,74.7Zm-1.552,75.544H77.355a2.59,2.59,0,0,1-2.581-2.76,2.648,2.648,0,0,1,2.672-2.414H100.38V139.9H81.236a7.761,7.761,0,0,1-7.761-7.761V125.41a7.761,7.761,0,0,1,7.761-7.761H100.38V106.783h-35.7a2.587,2.587,0,0,1-2.587-2.587V56.075a2.587,2.587,0,0,1,2.587-2.587h35.7V46.761a2.587,2.587,0,0,0-2.587-2.587H33.633a2.587,2.587,0,0,0-2.587,2.587V72.374H54.33a2.587,2.587,0,0,1,2.587,2.587V126.7a2.587,2.587,0,0,1-2.587,2.587H31.045v15.781H53.98a2.648,2.648,0,0,1,2.672,2.414,2.59,2.59,0,0,1-2.581,2.76H28.458a2.587,2.587,0,0,1-2.587-2.587V129.291H2.587A2.587,2.587,0,0,1,0,126.7V74.961a2.587,2.587,0,0,1,2.587-2.587H25.871V46.761A7.761,7.761,0,0,1,33.633,39H97.793a7.761,7.761,0,0,1,7.761,7.761v6.727h23.284a2.587,2.587,0,0,1,2.587,2.587V104.2a2.587,2.587,0,0,1-2.587,2.587H105.555v10.866h6.727a7.761,7.761,0,0,1,7.761,7.761v6.727a7.761,7.761,0,0,1-7.761,7.761h-6.727v7.761A2.587,2.587,0,0,1,102.967,150.246Zm-51.225-72.7H5.174v46.568H51.742Zm60.539,45.275H81.236a2.59,2.59,0,0,0-2.587,2.587v6.727a2.59,2.59,0,0,0,2.587,2.587h31.045a2.59,2.59,0,0,0,2.587-2.587V125.41A2.59,2.59,0,0,0,112.281,122.823ZM67.265,101.608h40.073L83.455,79.282a7.782,7.782,0,0,0-10.6,0l-5.589,5.225Zm58.986-42.946H67.265V77.424L69.321,75.5a12.971,12.971,0,0,1,17.667,0l27.927,26.106h11.336Z"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default FileIcon;
