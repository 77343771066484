import React, { FC } from "react";

const PrayIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="110.625"
      viewBox="0 0 118 110.625"
    >
      <g id="love" transform="translate(0 -16)">
        <path
          id="Path_394"
          data-name="Path 394"
          d="M176.855,36.742c0-8.019-5.248-20.742-19.329-20.742-5.786,0-10.652,2.728-14.508,8.119C139.162,18.728,134.295,16,128.509,16c-14.082,0-19.329,12.723-19.329,20.742,0,13.827,10.791,22.475,25.533,35.081,2.166,1.852,4.406,3.767,6.794,5.839a2.3,2.3,0,0,0,3.021,0c2.388-2.072,4.628-3.987,6.794-5.839,15.434-13.2,25.533-21.547,25.533-35.081ZM143.017,72.874c-1.842-1.589-3.6-3.091-5.309-4.554-15.423-13.188-23.919-20.453-23.919-31.578,0-7.546,4.937-16.133,14.72-16.133,5.106,0,9.19,2.932,12.486,8.962a2.3,2.3,0,0,0,4.044,0c3.3-6.03,7.38-8.962,12.486-8.962,9.646,0,14.72,8.459,14.72,16.133,0,11.125-8.5,18.39-23.919,31.578-1.711,1.463-3.467,2.965-5.309,4.554Z"
          transform="translate(-84.017)"
          fill="#51ced2"
        />
        <path
          id="Path_395"
          data-name="Path 395"
          d="M16.561,183.323l11.1,5.547v12.4a2.3,2.3,0,0,0,2.3,2.3h24.43a2.3,2.3,0,0,0,2.3-2.3V171.186a13.641,13.641,0,0,0-12.3-13.619l-.189-.02c-4.056-.407-11.237-3.18-14.072-6.016l-5.176-5.174a9.158,9.158,0,0,0-6.518-2.7V125.219a9.219,9.219,0,1,0-18.437,0v31.307a29.962,29.962,0,0,0,16.561,26.8Zm-11.952-58.1a4.609,4.609,0,1,1,9.219,0v19.673a9.217,9.217,0,0,0-1.909,14.5l11.8,11.8a2.3,2.3,0,0,0,3.26-3.259l-11.8-11.8a4.609,4.609,0,0,1,1.722-7.605h0a4.6,4.6,0,0,1,4.793,1.09l5.176,5.174c5.344,5.344,17.019,7.354,17.042,7.361a9.051,9.051,0,0,1,8.172,9.034v27.783H32.265V187.445a2.3,2.3,0,0,0-1.274-2.061L18.622,179.2A25.216,25.216,0,0,1,4.609,156.526Z"
          transform="translate(0 -76.953)"
          fill="#51ced2"
        />
        <ellipse
          id="Ellipse_31"
          data-name="Ellipse 31"
          cx="2.5"
          cy="2"
          rx="2.5"
          ry="2"
          transform="translate(103 99)"
          fill="#51ced2"
        />
        <path
          id="Path_396"
          data-name="Path 396"
          d="M313.477,116a9.229,9.229,0,0,0-9.219,9.219v18.438a9.156,9.156,0,0,0-6.517,2.7l-5.177,5.174c-2.835,2.835-10.016,5.609-14.089,6.017l-.153.017A13.646,13.646,0,0,0,266,171.186v30.088a2.3,2.3,0,0,0,2.3,2.3h24.43a2.3,2.3,0,0,0,2.3-2.3v-12.4l7.543-3.771a2.3,2.3,0,0,0-2.061-4.123l-8.818,4.408a2.3,2.3,0,0,0-1.274,2.061v11.523h-19.82V171.186a9.055,9.055,0,0,1,8.19-9.036l.153-.017c5-.5,13.276-3.748,16.871-7.342L301,149.617a4.6,4.6,0,0,1,4.793-1.089h0a4.609,4.609,0,0,1,1.722,7.606l-11.8,11.8a2.3,2.3,0,0,0,3.26,3.259l11.8-11.8a9.217,9.217,0,0,0-1.909-14.5V125.219a4.609,4.609,0,0,1,9.219,0v31.307a25.42,25.42,0,0,1-3.077,12.106,2.3,2.3,0,1,0,4.05,2.2,30.043,30.043,0,0,0,3.637-14.308V125.219A9.229,9.229,0,0,0,313.477,116Z"
          transform="translate(-204.695 -76.953)"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default PrayIcon;
