import React, { FC } from "react";

const ClockIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="120.997"
      viewBox="0 0 121 120.997"
    >
      <g id="time-management" transform="translate(-2 -2.001)">
        <path
          id="Path_382"
          data-name="Path 382"
          d="M31,56h4.033v4.033H31Z"
          transform="translate(29.483 54.898)"
          fill="#51ced2"
        />
        <path
          id="Path_383"
          data-name="Path 383"
          d="M0,0H4.033V4.033H0Z"
          transform="translate(95.278 98.149) rotate(-45)"
          fill="#51ced2"
        />
        <path
          id="Path_384"
          data-name="Path 384"
          d="M56,31h4.033v4.033H56Z"
          transform="translate(54.9 29.482)"
          fill="#51ced2"
        />
        <path
          id="Path_385"
          data-name="Path 385"
          d="M120.983,48.382h-14.3a45.471,45.471,0,0,0-2.954-7.143l10.106-10.108a2.017,2.017,0,0,0,0-2.852l-8.557-8.555A60.5,60.5,0,1,0,19.719,105.28l8.557,8.555a2.017,2.017,0,0,0,2.852,0l10.108-10.106a45.494,45.494,0,0,0,7.149,2.952v14.3A2.017,2.017,0,0,0,50.4,123H74.6a2.017,2.017,0,0,0,2.017-2.017v-14.3a45.493,45.493,0,0,0,7.143-2.952l10.108,10.106a2.017,2.017,0,0,0,2.852,0L113.83,96.725a2.017,2.017,0,0,0,0-2.852L103.725,83.766a45.5,45.5,0,0,0,2.958-7.151h14.3A2.017,2.017,0,0,0,123,74.6V50.4a2.017,2.017,0,0,0-2.017-2.017ZM22.57,22.568a56.444,56.444,0,0,1,78.4-1.392l-2.848,2.848A52.433,52.433,0,0,0,24.026,98.117l-2.848,2.848a56.444,56.444,0,0,1,1.391-78.4ZM79.611,45.387A24.2,24.2,0,1,1,45.388,79.61a2.017,2.017,0,0,0-2.851,0L26.88,95.267A48.4,48.4,0,0,1,95.269,26.878L79.611,42.533a2.017,2.017,0,0,0,0,2.854Zm39.355,27.195H105.181a2.017,2.017,0,0,0-1.944,1.476,41.321,41.321,0,0,1-3.753,9.075,2.017,2.017,0,0,0,.335,2.42l9.738,9.741L95.3,109.551l-9.741-9.738a2.017,2.017,0,0,0-2.42-.335,41.321,41.321,0,0,1-9.075,3.753,2.017,2.017,0,0,0-1.476,1.944v13.79H52.417V105.179a2.017,2.017,0,0,0-1.476-1.944,41.322,41.322,0,0,1-9.075-3.753,2.017,2.017,0,0,0-2.42.335l-9.74,9.738-5.7-5.7L44.017,83.839A28.233,28.233,0,0,0,83.84,44.012L103.854,24l5.7,5.7L99.818,39.44a2.017,2.017,0,0,0-.335,2.42,41.287,41.287,0,0,1,3.753,9.075,2.017,2.017,0,0,0,1.944,1.476h13.786Z"
          transform="translate(0 0)"
          fill="#51ced2"
        />
        <path
          id="Path_386"
          data-name="Path 386"
          d="M46.217,40.486V18.017a2.017,2.017,0,1,0-4.033,0v22.47A8.067,8.067,0,0,0,36.42,46.25h-14.4a2.017,2.017,0,0,0,0,4.033h14.4a8.051,8.051,0,1,0,9.8-9.8ZM44.2,52.3a4.033,4.033,0,1,1,4.033-4.033A4.033,4.033,0,0,1,44.2,52.3Z"
          transform="translate(18.3 14.232)"
          fill="#51ced2"
        />
        <path
          id="Path_387"
          data-name="Path 387"
          d="M33.017,17.067A2.017,2.017,0,0,1,31,15.05V11.017a2.017,2.017,0,0,1,4.033,0V15.05A2.017,2.017,0,0,1,33.017,17.067Z"
          transform="translate(29.483 7.115)"
          fill="#51ced2"
        />
        <path
          id="Path_388"
          data-name="Path 388"
          d="M43.016,19.475a2.017,2.017,0,0,1-1.744-3.025l2.017-3.495a2.017,2.017,0,1,1,3.493,2.017l-2.017,3.495a2.017,2.017,0,0,1-1.748,1.008Z"
          transform="translate(39.651 10.111)"
          fill="#51ced2"
        />
        <path
          id="Path_389"
          data-name="Path 389"
          d="M24.035,19.475a2.017,2.017,0,0,1-1.748-1.008l-2.017-3.495a2.017,2.017,0,1,1,3.493-2.017l2.017,3.495a2.017,2.017,0,0,1-1.744,3.025Z"
          transform="translate(18.299 10.111)"
          fill="#51ced2"
        />
        <path
          id="Path_390"
          data-name="Path 390"
          d="M17.458,26.05a2,2,0,0,1-1.008-.27l-3.495-2.017a2.017,2.017,0,1,1,2.017-3.493l3.495,2.017a2.017,2.017,0,0,1-1.008,3.763Z"
          transform="translate(10.112 18.298)"
          fill="#51ced2"
        />
        <path
          id="Path_391"
          data-name="Path 391"
          d="M15.05,35.033H11.017a2.017,2.017,0,0,1,0-4.033H15.05a2.017,2.017,0,0,1,0,4.033Z"
          transform="translate(7.117 29.482)"
          fill="#51ced2"
        />
        <path
          id="Path_392"
          data-name="Path 392"
          d="M13.965,47.05a2.017,2.017,0,0,1-1.008-3.763l3.495-2.017a2.017,2.017,0,1,1,2.017,3.493L14.973,46.78a2,2,0,0,1-1.008.27Z"
          transform="translate(10.114 39.648)"
          fill="#51ced2"
        />
        <path
          id="Path_393"
          data-name="Path 393"
          d="M39.483,59.67a19.993,19.993,0,0,1-14.254-5.917,2.017,2.017,0,1,1,2.856-2.848,16.123,16.123,0,1,0,22.8-22.8,2.017,2.017,0,1,1,2.847-2.855,20.167,20.167,0,0,1-14.25,34.42Z"
          transform="translate(23.017 22.995)"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default ClockIcon;
