import React, { FC } from "react";

const PillsIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99"
      height="105.469"
      viewBox="0 0 99 105.469"
    >
      <g id="medicine" transform="translate(-15.701)">
        <g
          id="Group_252"
          data-name="Group 252"
          transform="translate(36.299 25.954)"
        >
          <g id="Group_251" data-name="Group 251">
            <path
              id="Path_397"
              data-name="Path 397"
              d="M117.756,125.994a2.06,2.06,0,1,0,2.06,2.06A2.061,2.061,0,0,0,117.756,125.994Z"
              transform="translate(-115.696 -125.994)"
              fill="#51ced2"
            />
          </g>
        </g>
        <g
          id="Group_254"
          data-name="Group 254"
          transform="translate(38.359 56.233)"
        >
          <g id="Group_253" data-name="Group 253" transform="translate(0)">
            <path
              id="Path_398"
              data-name="Path 398"
              d="M144.234,281.225h-6.179v-6.179a2.06,2.06,0,1,0-4.12,0v6.179h-6.179a2.06,2.06,0,1,0,0,4.12h6.179v6.179a2.06,2.06,0,1,0,4.12,0v-6.179h6.179a2.06,2.06,0,1,0,0-4.12Z"
              transform="translate(-125.696 -272.986)"
              fill="#51ced2"
            />
          </g>
        </g>
        <g id="Group_256" data-name="Group 256" transform="translate(15.701)">
          <g id="Group_255" data-name="Group 255" transform="translate(0)">
            <path
              id="Path_399"
              data-name="Path 399"
              d="M112.493,66.327A16.469,16.469,0,0,0,89.983,60.3l-8.367,4.831V47.238a10.373,10.373,0,0,0-2.06-6.179l-10.3-13.732V21.48a6.189,6.189,0,0,0,4.12-5.825V6.179A6.187,6.187,0,0,0,67.2,0H30.12a6.187,6.187,0,0,0-6.18,6.179v9.475a6.19,6.19,0,0,0,4.12,5.825v5.847L17.76,41.06A10.372,10.372,0,0,0,15.7,47.238V87.131A10.311,10.311,0,0,0,26,97.43H59.1a16.462,16.462,0,0,0,22.384,5.825l24.974-14.419A16.491,16.491,0,0,0,112.493,66.327ZM28.06,6.179a2.062,2.062,0,0,1,2.06-2.06H67.2a2.062,2.062,0,0,1,2.06,2.06v9.475a2.062,2.062,0,0,1-2.06,2.06H30.12a2.062,2.062,0,0,1-2.06-2.06V6.179Zm-7,37.351L31.768,29.25a2.06,2.06,0,0,0,.412-1.236V21.834H65.137v4.12H46.6a2.06,2.06,0,0,0,0,4.12H66.167L76.259,43.53a6.233,6.233,0,0,1,1.193,3.023H19.865A6.23,6.23,0,0,1,21.057,43.53ZM26,93.31a6.186,6.186,0,0,1-6.179-6.179v-2.08H57.245a16.505,16.505,0,0,0,.1,8.26H26ZM58.86,80.951H19.821V50.672H77.5V67.5l-12.487,7.21A16.356,16.356,0,0,0,58.86,80.951ZM79.428,99.688A12.359,12.359,0,1,1,67.069,78.282L78.25,71.826,90.609,93.232ZM104.4,85.269l-10.225,5.9L81.818,69.766l10.225-5.9A12.359,12.359,0,1,1,104.4,85.269Z"
              transform="translate(-15.701)"
              fill="#51ced2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PillsIcon;
