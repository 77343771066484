import React, { FC } from "react";

const Waves: FC = () => {
  return (
    <svg
      id="Group_2"
      data-name="Group 2"
      xmlns="http://www.w3.org/2000/svg"
      width="640.494"
      height="637.496"
      preserveAspectRatio="none"
      viewBox="0 0 900.494 637.496"
    >
      <path
        id="Path_1"
        data-name="Path 1"
        d="M293.5,655.7c5.644,5.968,9.053,9.571,9.053,17.8s-3.409,11.839-9.053,17.8c-6.669,7.054-15.807,16.716-15.807,34.764s9.138,27.708,15.807,34.762c5.644,5.965,9.053,9.566,9.053,17.8s-3.409,11.835-9.053,17.8c-6.669,7.052-15.807,16.713-15.807,34.762s9.138,27.708,15.807,34.76c5.644,5.967,9.053,9.571,9.053,17.8s-3.409,11.835-9.053,17.8c-6.669,7.054-15.807,16.716-15.807,34.762s9.136,27.713,15.807,34.769c5.644,5.967,9.053,9.568,9.053,17.8s-3.409,11.837-9.053,17.8c-6.669,7.054-15.807,16.714-15.807,34.762s9.138,27.71,15.807,34.764c5.644,5.967,9.053,9.57,9.053,17.8s-3.409,11.838-9.053,17.8c-6.671,7.054-15.807,16.716-15.807,34.762s9.138,27.71,15.807,34.766c5.644,5.965,9.053,9.568,9.053,17.8s-3.409,11.839-9.053,17.807c-4.784,5.059-10.837,11.462-13.878,21.338h27.988c1.162-1.381,2.495-2.8,3.991-4.378,5.156-5.449,11.776-12.459,14.522-23.685v-22.159c-2.746-11.226-9.366-18.235-14.522-23.685-5.644-5.967-9.049-9.571-9.049-17.807s3.4-11.835,9.049-17.8c5.156-5.45,11.776-12.459,14.522-23.685v-22.159c-2.746-11.226-9.366-18.237-14.522-23.685-5.644-5.967-9.049-9.571-9.049-17.8s3.4-11.837,9.049-17.8c5.156-5.449,11.776-12.457,14.522-23.683V977.816c-2.746-11.226-9.366-18.235-14.522-23.685-5.644-5.968-9.049-9.569-9.049-17.809s3.4-11.835,9.049-17.8c5.156-5.447,11.776-12.459,14.522-23.683V872.678c-2.746-11.224-9.366-18.233-14.522-23.681-5.644-5.967-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c5.156-5.448,11.776-12.457,14.522-23.681V767.556c-2.746-11.226-9.366-18.235-14.522-23.683-5.644-5.968-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c5.156-5.449,11.776-12.457,14.522-23.682V662.422c-2.746-11.226-9.366-18.235-14.522-23.685-5.644-5.965-9.049-9.569-9.049-17.8s3.4-11.835,9.049-17.8c.7-.738,1.428-1.511,2.168-2.315H282.352a43,43,0,0,0-4.661,20.12C277.691,638.981,286.827,648.643,293.5,655.7Z"
        transform="translate(314.372 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M265.725,655.7c5.641,5.968,9.051,9.571,9.051,17.8s-3.407,11.839-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.764s9.138,27.708,15.809,34.762c5.644,5.965,9.051,9.566,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.052-15.809,16.713-15.809,34.762s9.138,27.708,15.809,34.76c5.644,5.967,9.051,9.571,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.762s9.138,27.713,15.809,34.769c5.644,5.967,9.051,9.568,9.051,17.8s-3.407,11.837-9.051,17.8c-6.671,7.054-15.809,16.714-15.809,34.762s9.138,27.71,15.809,34.764c5.644,5.967,9.051,9.57,9.051,17.8s-3.407,11.838-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.762s9.138,27.71,15.809,34.766c5.644,5.965,9.051,9.568,9.051,17.8s-3.409,11.839-9.051,17.807c-4.784,5.059-10.837,11.462-13.88,21.338h27.99c1.16-1.381,2.492-2.8,3.991-4.378,6.669-7.054,15.807-16.716,15.807-34.766s-9.138-27.708-15.807-34.762c-5.646-5.967-9.051-9.571-9.051-17.807s3.4-11.835,9.051-17.8c6.669-7.054,15.807-16.716,15.807-34.765s-9.136-27.71-15.807-34.764c-5.646-5.967-9.051-9.571-9.051-17.8s3.4-11.837,9.051-17.8c6.669-7.054,15.807-16.714,15.807-34.762s-9.136-27.708-15.807-34.764c-5.646-5.968-9.051-9.569-9.051-17.809s3.4-11.835,9.051-17.8c6.669-7.054,15.807-16.716,15.807-34.762S290.5,856.05,283.827,849c-5.646-5.967-9.051-9.569-9.051-17.8s3.4-11.837,9.051-17.8c6.669-7.052,15.807-16.714,15.807-34.76s-9.138-27.708-15.807-34.76c-5.646-5.968-9.051-9.569-9.051-17.8s3.4-11.837,9.051-17.8c6.671-7.054,15.807-16.714,15.807-34.764s-9.138-27.711-15.807-34.764c-5.646-5.965-9.051-9.569-9.051-17.8s3.4-11.835,9.051-17.8c.7-.738,1.428-1.511,2.168-2.315H254.577a42.993,42.993,0,0,0-4.661,20.12C249.916,638.981,259.054,648.643,265.725,655.7Z"
        transform="translate(282.928 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_3"
        data-name="Path 3"
        d="M237.951,655.7c5.642,5.968,9.049,9.571,9.049,17.8s-3.4,11.839-9.049,17.8c-6.671,7.054-15.81,16.716-15.81,34.764s9.138,27.708,15.81,34.762C243.6,766.8,247,770.4,247,778.633s-3.4,11.835-9.049,17.8c-6.671,7.052-15.81,16.713-15.81,34.762s9.138,27.708,15.81,34.76c5.644,5.967,9.049,9.571,9.049,17.8s-3.4,11.835-9.049,17.8c-6.671,7.054-15.81,16.716-15.81,34.762s9.138,27.713,15.81,34.769C243.6,977.058,247,980.66,247,988.9s-3.4,11.837-9.049,17.8c-6.671,7.054-15.81,16.714-15.81,34.762s9.138,27.71,15.81,34.764c5.644,5.967,9.049,9.57,9.049,17.8s-3.4,11.838-9.049,17.8c-6.671,7.054-15.81,16.716-15.81,34.762s9.138,27.71,15.81,34.766c5.644,5.965,9.049,9.568,9.049,17.8s-3.407,11.839-9.049,17.807c-4.787,5.059-10.84,11.462-13.88,21.338h27.99c1.16-1.381,2.492-2.8,3.989-4.378,6.669-7.054,15.809-16.716,15.809-34.766s-9.14-27.708-15.809-34.762c-5.644-5.967-9.051-9.571-9.051-17.807s3.407-11.835,9.051-17.8c6.669-7.054,15.809-16.716,15.809-34.765s-9.138-27.71-15.809-34.764c-5.644-5.967-9.051-9.571-9.051-17.8s3.407-11.837,9.051-17.8c6.669-7.054,15.809-16.714,15.809-34.762s-9.138-27.711-15.809-34.764c-5.644-5.968-9.051-9.569-9.051-17.809s3.407-11.835,9.051-17.8c6.669-7.054,15.809-16.716,15.809-34.762S262.72,856.047,256.051,849c-5.644-5.965-9.051-9.567-9.051-17.8s3.407-11.837,9.051-17.8c6.669-7.054,15.809-16.714,15.809-34.76s-9.14-27.708-15.809-34.76c-5.644-5.968-9.051-9.571-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.054,15.809-16.714,15.809-34.764s-9.14-27.711-15.809-34.764c-5.644-5.965-9.051-9.569-9.051-17.8s3.407-11.835,9.051-17.8c.7-.738,1.429-1.511,2.168-2.315H226.8a43,43,0,0,0-4.661,20.12C222.142,638.981,231.28,648.643,237.951,655.7Z"
        transform="translate(251.486 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M210.176,655.7c5.644,5.968,9.051,9.571,9.051,17.8s-3.4,11.839-9.051,17.8c-6.669,7.054-15.809,16.716-15.809,34.764s9.14,27.708,15.809,34.762c5.646,5.965,9.051,9.566,9.051,17.8s-3.4,11.835-9.051,17.8c-6.669,7.052-15.809,16.713-15.809,34.762s9.14,27.708,15.809,34.76c5.646,5.967,9.051,9.571,9.051,17.8s-3.4,11.835-9.051,17.8c-6.669,7.054-15.809,16.716-15.809,34.762s9.138,27.713,15.809,34.769c5.646,5.967,9.051,9.568,9.051,17.8s-3.4,11.837-9.051,17.8c-6.669,7.054-15.809,16.714-15.809,34.762s9.14,27.71,15.809,34.764c5.646,5.967,9.051,9.57,9.051,17.8s-3.4,11.838-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.762s9.14,27.71,15.809,34.766c5.646,5.965,9.051,9.568,9.051,17.8s-3.407,11.839-9.051,17.807c-4.784,5.059-10.837,11.462-13.88,21.338h27.99c1.16-1.381,2.492-2.8,3.989-4.378,6.673-7.054,15.809-16.716,15.809-34.766s-9.136-27.708-15.809-34.762c-5.644-5.967-9.049-9.571-9.049-17.807s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.765s-9.136-27.71-15.809-34.764c-5.642-5.967-9.049-9.571-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.762s-9.136-27.708-15.809-34.764c-5.642-5.968-9.049-9.569-9.049-17.809s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.762S234.949,856.05,228.276,849c-5.644-5.967-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.052,15.809-16.714,15.809-34.76s-9.136-27.708-15.809-34.76c-5.644-5.968-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.764s-9.136-27.711-15.809-34.764c-5.644-5.965-9.049-9.569-9.049-17.8s3.4-11.835,9.049-17.8c.7-.738,1.428-1.511,2.17-2.315H199.03a42.994,42.994,0,0,0-4.663,20.12C194.367,638.981,203.505,648.643,210.176,655.7Z"
        transform="translate(220.042 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_5"
        data-name="Path 5"
        d="M182.4,655.7c5.644,5.965,9.051,9.568,9.051,17.8s-3.407,11.839-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.764s9.138,27.71,15.809,34.764c5.644,5.963,9.051,9.564,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.052-15.809,16.714-15.809,34.76s9.138,27.708,15.809,34.76c5.644,5.967,9.051,9.571,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.762s9.136,27.715,15.809,34.769c5.644,5.967,9.051,9.568,9.051,17.807s-3.407,11.835-9.051,17.8c-6.671,7.054-15.809,16.714-15.809,34.762s9.138,27.71,15.809,34.764c5.644,5.967,9.051,9.57,9.051,17.8s-3.407,11.838-9.051,17.8c-6.673,7.054-15.809,16.716-15.809,34.762s9.138,27.712,15.809,34.766c5.644,5.967,9.051,9.568,9.051,17.8s-3.407,11.839-9.051,17.807c-4.787,5.059-10.84,11.462-13.88,21.338h27.99c1.16-1.381,2.492-2.8,3.989-4.378,6.673-7.054,15.809-16.716,15.809-34.766s-9.136-27.708-15.809-34.762c-5.642-5.967-9.049-9.571-9.049-17.807s3.407-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.765s-9.136-27.71-15.809-34.764c-5.642-5.967-9.049-9.571-9.049-17.8s3.407-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.76s-9.136-27.711-15.809-34.767c-5.642-5.968-9.049-9.569-9.049-17.809s3.407-11.833,9.049-17.8c6.673-7.052,15.809-16.714,15.809-34.762S207.175,856.05,200.5,849c-5.642-5.967-9.049-9.569-9.049-17.8s3.407-11.837,9.049-17.8c6.673-7.052,15.809-16.714,15.809-34.76s-9.136-27.708-15.809-34.76c-5.642-5.968-9.049-9.569-9.049-17.8s3.407-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.764s-9.136-27.711-15.809-34.764c-5.642-5.965-9.049-9.569-9.049-17.8s3.407-11.835,9.049-17.8c.7-.741,1.429-1.513,2.173-2.318H171.256a42.994,42.994,0,0,0-4.663,20.12C166.593,638.981,175.729,648.643,182.4,655.7Z"
        transform="translate(188.599 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        d="M154.626,655.7c5.646,5.968,9.051,9.571,9.051,17.8s-3.4,11.839-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.764s9.136,27.708,15.807,34.762c5.646,5.965,9.051,9.566,9.051,17.8s-3.4,11.835-9.051,17.8c-6.671,7.052-15.807,16.713-15.807,34.762s9.136,27.708,15.807,34.76c5.646,5.967,9.051,9.571,9.051,17.8s-3.4,11.835-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.762s9.136,27.713,15.807,34.769c5.646,5.967,9.051,9.568,9.051,17.8s-3.4,11.837-9.051,17.8c-6.671,7.054-15.807,16.714-15.807,34.762s9.136,27.71,15.807,34.764c5.646,5.967,9.051,9.57,9.051,17.8s-3.4,11.838-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.762s9.136,27.71,15.807,34.766c5.646,5.965,9.051,9.568,9.051,17.8s-3.4,11.839-9.051,17.807c-4.784,5.059-10.837,11.462-13.876,21.338h27.986c1.16-1.381,2.492-2.8,3.991-4.378,6.671-7.054,15.809-16.716,15.809-34.766s-9.138-27.708-15.809-34.762c-5.646-5.967-9.051-9.571-9.051-17.807s3.4-11.835,9.051-17.8c6.671-7.054,15.809-16.716,15.809-34.765s-9.136-27.71-15.809-34.764c-5.642-5.967-9.051-9.571-9.051-17.8s3.4-11.837,9.051-17.8c6.671-7.054,15.809-16.714,15.809-34.762s-9.136-27.708-15.809-34.764c-5.642-5.968-9.051-9.569-9.051-17.809s3.4-11.835,9.051-17.8c6.671-7.054,15.809-16.716,15.809-34.762S179.4,856.05,172.728,849c-5.646-5.967-9.051-9.569-9.051-17.8s3.4-11.837,9.051-17.8c6.671-7.052,15.809-16.714,15.809-34.76s-9.138-27.708-15.809-34.76c-5.646-5.968-9.051-9.569-9.051-17.8s3.4-11.837,9.051-17.8c6.673-7.054,15.809-16.714,15.809-34.764s-9.138-27.711-15.809-34.764c-5.646-5.965-9.051-9.569-9.051-17.8s3.4-11.835,9.051-17.8c.7-.738,1.429-1.511,2.17-2.315H143.48a43.021,43.021,0,0,0-4.661,20.12C138.819,638.981,147.955,648.643,154.626,655.7Z"
        transform="translate(157.156 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M126.852,655.7c5.644,5.968,9.051,9.571,9.051,17.8s-3.407,11.839-9.051,17.8c-6.671,7.056-15.807,16.718-15.807,34.767s9.136,27.708,15.807,34.76c5.644,5.968,9.051,9.569,9.051,17.8s-3.407,11.837-9.051,17.8c-6.671,7.052-15.807,16.713-15.807,34.762s9.136,27.708,15.807,34.76c5.644,5.967,9.051,9.568,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.762s9.134,27.711,15.807,34.769c5.644,5.967,9.051,9.568,9.051,17.8s-3.407,11.837-9.051,17.8c-6.671,7.056-15.807,16.716-15.807,34.764s9.136,27.71,15.807,34.764c5.644,5.967,9.051,9.57,9.051,17.8s-3.407,11.838-9.051,17.8c-6.673,7.054-15.807,16.716-15.807,34.762s9.136,27.71,15.807,34.766c5.644,5.965,9.051,9.568,9.051,17.8s-3.407,11.839-9.051,17.807c-4.787,5.059-10.837,11.462-13.878,21.338H140.96c1.162-1.381,2.495-2.8,3.991-4.378,6.673-7.054,15.809-16.716,15.809-34.766s-9.136-27.708-15.809-34.762c-5.644-5.967-9.049-9.571-9.049-17.807s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.765s-9.136-27.71-15.809-34.764c-5.644-5.967-9.049-9.571-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.762s-9.136-27.708-15.809-34.767c-5.644-5.965-9.049-9.567-9.049-17.807s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.762S151.625,856.05,144.952,849c-5.644-5.967-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.052,15.809-16.716,15.809-34.762s-9.136-27.706-15.809-34.758c-5.644-5.968-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.764s-9.136-27.711-15.809-34.764c-5.644-5.965-9.049-9.569-9.049-17.8s3.4-11.835,9.049-17.8c.7-.738,1.429-1.511,2.17-2.315H115.706a43,43,0,0,0-4.661,20.12C111.045,638.981,120.179,648.643,126.852,655.7Z"
        transform="translate(125.713 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M99.079,655.7c5.642,5.968,9.051,9.571,9.051,17.8s-3.407,11.839-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.764s9.138,27.708,15.809,34.762c5.644,5.965,9.051,9.566,9.051,17.8s-3.407,11.835-9.051,17.8C92.408,803.485,83.27,813.147,83.27,831.2s9.138,27.708,15.809,34.76c5.644,5.967,9.051,9.571,9.051,17.8s-3.407,11.835-9.051,17.8c-6.671,7.054-15.809,16.716-15.809,34.762s9.136,27.713,15.809,34.769c5.644,5.967,9.051,9.568,9.051,17.8s-3.407,11.837-9.051,17.8c-6.671,7.054-15.809,16.714-15.809,34.762s9.138,27.71,15.809,34.764c5.644,5.967,9.051,9.57,9.051,17.8s-3.407,11.838-9.051,17.8c-6.673,7.054-15.809,16.716-15.809,34.762s9.138,27.71,15.809,34.766c5.644,5.965,9.051,9.568,9.051,17.8s-3.409,11.839-9.051,17.807c-4.787,5.059-10.837,11.462-13.88,21.338h27.99c1.16-1.381,2.495-2.8,3.989-4.378,6.673-7.054,15.809-16.716,15.809-34.766s-9.136-27.708-15.809-34.762c-5.644-5.967-9.049-9.571-9.049-17.807s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.765s-9.136-27.71-15.809-34.764c-5.644-5.967-9.049-9.571-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.762s-9.136-27.708-15.809-34.764c-5.644-5.968-9.049-9.569-9.049-17.809s3.4-11.835,9.049-17.8c6.673-7.054,15.809-16.716,15.809-34.762S123.852,856.05,117.179,849c-5.644-5.967-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.052,15.809-16.714,15.809-34.76s-9.136-27.708-15.809-34.76c-5.644-5.968-9.049-9.569-9.049-17.8s3.4-11.837,9.049-17.8c6.673-7.054,15.809-16.714,15.809-34.764s-9.136-27.711-15.809-34.764c-5.644-5.965-9.049-9.569-9.049-17.8s3.4-11.835,9.049-17.8c.7-.738,1.428-1.511,2.17-2.315H87.933a42.994,42.994,0,0,0-4.663,20.12C83.27,638.981,92.406,648.643,99.079,655.7Z"
        transform="translate(94.269 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M71.305,655.7c5.642,5.968,9.049,9.571,9.049,17.8s-3.4,11.839-9.049,17.8C64.634,698.36,55.5,708.022,55.5,726.071s9.138,27.708,15.809,34.762c5.646,5.965,9.049,9.566,9.049,17.8s-3.4,11.835-9.049,17.8C64.634,803.485,55.5,813.147,55.5,831.2s9.138,27.708,15.809,34.76c5.646,5.967,9.049,9.571,9.049,17.8s-3.4,11.835-9.049,17.8C64.634,908.614,55.5,918.276,55.5,936.323s9.136,27.713,15.809,34.769c5.646,5.967,9.049,9.568,9.049,17.8s-3.4,11.837-9.049,17.8c-6.671,7.054-15.809,16.714-15.809,34.762s9.138,27.71,15.809,34.764c5.646,5.967,9.049,9.57,9.049,17.8s-3.4,11.838-9.049,17.8c-6.673,7.054-15.809,16.716-15.809,34.762s9.138,27.71,15.809,34.766c5.646,5.965,9.049,9.568,9.049,17.8s-3.407,11.839-9.049,17.807c-4.787,5.059-10.84,11.462-13.88,21.338H85.414c1.16-1.381,2.495-2.8,3.991-4.378,6.671-7.054,15.809-16.716,15.809-34.766S96.076,1171.456,89.4,1164.4c-5.644-5.967-9.051-9.571-9.051-17.807s3.407-11.835,9.051-17.8c6.671-7.054,15.809-16.716,15.809-34.765s-9.138-27.71-15.809-34.764c-5.644-5.967-9.051-9.571-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.054,15.809-16.714,15.809-34.762S96.076,961.187,89.4,954.131c-5.644-5.968-9.051-9.569-9.051-17.809s3.407-11.835,9.051-17.8c6.671-7.054,15.809-16.716,15.809-34.762S96.076,856.05,89.4,849c-5.644-5.967-9.051-9.569-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.052,15.809-16.714,15.809-34.76s-9.138-27.708-15.809-34.76c-5.644-5.968-9.051-9.569-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.054,15.809-16.714,15.809-34.764S96.076,645.791,89.4,638.737c-5.644-5.965-9.051-9.569-9.051-17.8s3.407-11.835,9.051-17.8c.7-.738,1.428-1.511,2.17-2.315H60.159a42.968,42.968,0,0,0-4.663,20.12C55.5,638.981,64.632,648.643,71.305,655.7Z"
        transform="translate(62.827 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M43.529,655.7c5.642,5.968,9.051,9.571,9.051,17.8s-3.4,11.839-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.764s9.136,27.708,15.807,34.762c5.646,5.965,9.051,9.566,9.051,17.8s-3.4,11.835-9.051,17.8c-6.671,7.052-15.807,16.713-15.807,34.762s9.136,27.708,15.807,34.76c5.646,5.967,9.051,9.571,9.051,17.8s-3.4,11.835-9.051,17.8c-6.671,7.054-15.807,16.716-15.807,34.762s9.134,27.713,15.807,34.769c5.646,5.967,9.051,9.568,9.051,17.8s-3.4,11.837-9.051,17.8c-6.671,7.054-15.807,16.714-15.807,34.762s9.136,27.71,15.807,34.764c5.646,5.967,9.051,9.57,9.051,17.8s-3.4,11.838-9.051,17.8c-6.673,7.054-15.807,16.716-15.807,34.762s9.136,27.71,15.807,34.766c5.646,5.965,9.051,9.568,9.051,17.8s-3.409,11.839-9.051,17.807c-4.784,5.059-10.837,11.462-13.878,21.338H57.64c1.16-1.381,2.495-2.8,3.991-4.378,6.671-7.054,15.807-16.716,15.807-34.766S68.3,1171.456,61.631,1164.4c-5.644-5.967-9.051-9.571-9.051-17.807s3.407-11.835,9.051-17.8c6.671-7.054,15.807-16.716,15.807-34.765s-9.136-27.71-15.807-34.764c-5.644-5.967-9.051-9.571-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.054,15.807-16.714,15.807-34.762S68.3,961.187,61.631,954.131c-5.644-5.968-9.051-9.569-9.051-17.809s3.407-11.835,9.051-17.8c6.671-7.054,15.807-16.716,15.807-34.762S68.3,856.05,61.631,849c-5.644-5.967-9.051-9.569-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.052,15.807-16.714,15.807-34.76s-9.136-27.708-15.807-34.76c-5.644-5.968-9.051-9.569-9.051-17.8s3.407-11.837,9.051-17.8c6.671-7.054,15.807-16.714,15.807-34.764S68.3,645.791,61.631,638.737c-5.644-5.965-9.051-9.569-9.051-17.8s3.407-11.835,9.051-17.8c.7-.738,1.429-1.511,2.168-2.315H32.383a43,43,0,0,0-4.661,20.12C27.722,638.981,36.856,648.643,43.529,655.7Z"
        transform="translate(31.384 -600.813)"
        fill="inherit"
      />
      <path
        id="Path_11"
        data-name="Path 11"
        d="M0,624.326C1.058,640.216,9.454,649.1,15.7,655.7c5.642,5.968,9.049,9.571,9.049,17.8s-3.4,11.839-9.049,17.8C9.456,697.9,1.058,706.787,0,722.679v6.782c1.058,15.892,9.456,24.771,15.7,31.371,5.644,5.965,9.049,9.566,9.049,17.8s-3.4,11.835-9.049,17.8C9.456,803.031,1.058,811.912,0,827.8v6.782c1.058,15.891,9.456,24.771,15.7,31.369,5.644,5.967,9.049,9.571,9.049,17.8s-3.4,11.835-9.049,17.8C9.456,908.16,1.058,917.041,0,932.934v6.782c1.058,15.892,9.454,24.776,15.7,31.375,5.644,5.967,9.049,9.568,9.049,17.8s-3.4,11.837-9.049,17.8c-6.241,6.6-14.639,15.479-15.7,31.369v6.784c1.058,15.893,9.456,24.774,15.7,31.374,5.644,5.967,9.049,9.57,9.049,17.8s-3.4,11.838-9.049,17.8c-6.243,6.6-14.639,15.481-15.7,31.373v6.782c1.058,15.893,9.456,24.773,15.7,31.373,5.644,5.965,9.049,9.568,9.049,17.8S21.338,1211,15.7,1216.971c-4.787,5.059-10.84,11.462-13.878,21.338H29.807c1.158-1.381,2.492-2.8,3.991-4.378,6.669-7.054,15.807-16.716,15.807-34.766S40.467,1171.456,33.8,1164.4c-5.646-5.967-9.053-9.571-9.053-17.807s3.407-11.835,9.053-17.8c6.669-7.054,15.807-16.716,15.807-34.765s-9.138-27.71-15.807-34.764c-5.646-5.967-9.053-9.571-9.053-17.8s3.407-11.837,9.053-17.8c6.669-7.054,15.807-16.714,15.807-34.762S40.467,961.187,33.8,954.131c-5.646-5.968-9.053-9.569-9.053-17.809s3.407-11.835,9.053-17.8c6.669-7.054,15.807-16.716,15.807-34.762S40.467,856.05,33.8,849c-5.646-5.967-9.053-9.569-9.053-17.8s3.407-11.837,9.053-17.8c6.669-7.052,15.807-16.714,15.807-34.76s-9.138-27.708-15.807-34.76c-5.646-5.968-9.053-9.569-9.053-17.8s3.407-11.837,9.053-17.8c6.669-7.054,15.807-16.714,15.807-34.764S40.467,645.791,33.8,638.737c-5.646-5.965-9.053-9.569-9.053-17.8s3.407-11.835,9.053-17.8c.7-.738,1.426-1.511,2.168-2.315H4.55A42.056,42.056,0,0,0,0,617.542Z"
        transform="translate(0 -600.813)"
        fill="inherit"
      />
    </svg>
  );
};

export default Waves;
