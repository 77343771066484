import React, { FC } from "react";

const TargetIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128.091"
      height="128.091"
      viewBox="0 0 128.091 128.091"
    >
      <path
        id="dartboard"
        d="M119.336,31.871l4.225-.549a1.752,1.752,0,0,0,1.012-.5l2.914-2.914a1.75,1.75,0,0,0-.543-2.844l-12.16-5.258-6.6-6.594L102.931,1.053A1.75,1.75,0,0,0,100.087.51L97.173,3.424a1.746,1.746,0,0,0-.5,1.011L96.126,8.66a64.043,64.043,0,1,0,23.21,23.211ZM91.427,50.677A30.529,30.529,0,1,1,77.32,36.57L70.6,42.206a1.747,1.747,0,0,0-.623,1.249l-.132,2.483A18.632,18.632,0,0,0,64,45,19,19,0,1,0,83,64a18.63,18.63,0,0,0-.941-5.841l2.484-.132a1.748,1.748,0,0,0,1.248-.624Zm19.985-29.291L83.6,54.569l-10.739.569L73.428,44.4l33.183-27.815ZM79.5,64A15.5,15.5,0,1,1,64,48.5a15.2,15.2,0,0,1,5.648,1.085L69.3,56.226,62.763,62.76a1.749,1.749,0,0,0,2.474,2.474L71.771,58.7l6.644-.352A15.2,15.2,0,0,1,79.5,64Zm29.526-34.316,5.26-6.275,8.9,3.85-.672.672ZM80.212,34.146A34.087,34.087,0,1,0,93.851,47.785l7.8-9.3A45.015,45.015,0,0,1,109.5,64,45.5,45.5,0,1,1,64,18.5a45.023,45.023,0,0,1,25.517,7.849ZM100.74,4.806l3.851,8.905L98.316,18.97l1.752-13.493ZM64,124.5A60.5,60.5,0,1,1,95.635,12.438l-1.281,9.854-2.064,1.73A48.5,48.5,0,0,0,64,15a49,49,0,1,0,49,49,48.5,48.5,0,0,0-9.025-28.29l1.731-2.065,9.854-1.28A60.487,60.487,0,0,1,64,124.5Z"
        transform="translate(0.086 0.01)"
        fill="#51ced2"
      />
    </svg>
  );
};

export default TargetIcon;
