import React, { FC } from "react";

const Angular: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="305.535"
      height="612"
      viewBox="0 0 305.535 612"
    >
      <g id="Group_258" data-name="Group 258" transform="translate(0 306.72)">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M1358.823,152.638l-74.739-76.774L1357.938,0H1274.85L1201,75.863l74.738,76.774L1201,229.41l73.856,75.869h83.085l-73.855-75.868Z"
          transform="translate(-1200.997 0)"
          fill="inherit"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M1503.374,152.639l-74.737-76.773L1502.491,0H1419.4l-73.854,75.865,74.738,76.773-74.738,76.772L1419.4,305.28h83.086l-73.854-75.867Z"
          transform="translate(-1197.839 0)"
          fill="inherit"
        />
      </g>
      <g id="Group_259" data-name="Group 259" transform="translate(0)">
        <path
          id="Path_14-2"
          data-name="Path 14"
          d="M1358.823,153.483l-74.739-77.2L1357.938,0H1274.85L1201,76.283l74.738,77.2L1201,230.679l73.856,76.289h83.085l-73.855-76.288Z"
          transform="translate(-1200.997)"
          fill="inherit"
        />
        <path
          id="Path_15-2"
          data-name="Path 15"
          d="M1503.374,153.483l-74.737-77.2L1502.491,0H1419.4l-73.854,76.285,74.738,77.2-74.738,77.2,73.854,76.288h83.086l-73.854-76.287Z"
          transform="translate(-1197.839)"
          fill="inherit"
        />
      </g>
    </svg>
  );
};

export default Angular;
