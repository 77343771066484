import React from "react";
import { Button, createStyles } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";

const RoundedWhiteButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      backgroundColor: theme.palette.common.white,
      minWidth: "345px",
      minHeight: "50px",
    },
  })
)((props: any) => <Button {...props} />);

export default RoundedWhiteButton;
