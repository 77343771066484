import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    cyan: {
      color: "#50C7CB",
    },
    pink: {
      color: "#FD1B90",
    },
    blue_gray: {
      color: "#718DA7",
    },
    mobile_app_image: {
      width: "100%",
      height: "auto",
    },
    blog_article_item: {
      display: "flex",
      flexDirection: "column",

      "& img": {
        objectFit: "cover",
        height: "auto",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        maxHeight: 150,
      },

      "& .MuiTypography-h5": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },

      "& a": {
        marginTop: theme.spacing(2),
        color: "#38C1C4",
        textDecoration: "none",
        fontWeight: "bold",
      },
    },
    download_links: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",

      "& img": {
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        height: "auto",
      },

      "& a": {
        maxWidth: 150,
      },

      "& a:last-of-type": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

export default useStyles;
