import React, { FC, useEffect } from "react";
import { Typography, Link } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";

import useHomeStyles from "./homeStyles";

import { ContactUsContentQuery } from "../../graphql-types";

interface Props {}

const ContactUsForm: FC<Props> = () => {
  const homeClasses = useHomeStyles();

  const {
    strapiContactUsContent,
  } = useStaticQuery<ContactUsContentQuery>(graphql`
    query ContactUsContent {
      strapiContactUsContent {
        subtitle
        title
      }
    }
  `);

  return (
    <>
      <div className={homeClasses.centered_title}>
        <Typography variant="h4">
          <b>{strapiContactUsContent.title}</b>
        </Typography>
        <Typography variant="body2">
          {strapiContactUsContent.subtitle}
          <Link href="mailto:hello@flourish.zone" variant="body2">
            hello@flourish.zone
          </Link>
        </Typography>
      </div>
    </>
  );
};

export default ContactUsForm;
