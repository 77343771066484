import React, {useState, useEffect} from "react";
import CookieBanner from '../CookieBanner'
import { Typography, Link } from "@material-ui/core";
import useStyles from "./styles";

const Footer: React.FC = () => {
  const classes = useStyles();
  const [acceptCookie, setAcceptCookie] = useState(true)

  const checkCookie = (name) => {
    var cookie = document.cookie;
    var prefix = name + "=";
    var begin = cookie.indexOf("; " + prefix);
    if (begin == -1) {
        begin = cookie.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = cookie.length;
        }
    }
    if (decodeURIComponent(cookie.substring(begin + prefix.length, end))) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const showCookieBanner = checkCookie('AcceptCookies');
    if(showCookieBanner) {
      setAcceptCookie(false)
    }
  }, []);

  return (
    <>
    <div className={classes.container}>
      <div className={classes.Suny}>
        <img src="/footer-suny.png" alt="flourish-zone-suny" />
      </div>
      <div className={classes.brandInfo}>
        <Typography variant="body1" className={classes.brandName}>
          Flourish Zone Ltd <sup>&reg;</sup>
          <br />
          Registered company 4997248
          <br />
          1 Widcombe Crescent
          <br />
          Bath BA2 6AH{" "}
          <Link href="https://goo.gl/maps/YdqaJynpUZPLfUmj8" variant="body2">
            Map
          </Link>
          <br />
          <br />
          <Link href="mailto:hello@flourish.zone">hello@flourish.zone</Link>
          <br />
          <Link href="/terms">App terms and conditions</Link> -{" "}
          <Link href="/privacy-policy">Privacy policy</Link>
        </Typography>
      </div>
      <div className={classes.brandInfo}>
        <Typography variant="body1" className={classes.brandName, classes.footerLinks}>
          <Link href="/fz-for-you">Flourishzone For You</Link>
          <br/>
          <Link href="/fz-for-coaches">Flourishzone for Coaches</Link>
          <br/>
          <Link href="/fz-for-business">Flourishzone for Business</Link>
          <br/>
          <Link href="/about-us">About us</Link>
          <br/>
          <Link href="/blog">Blog</Link>
        </Typography>
      </div>
    </div>
    {acceptCookie ? (
      <CookieBanner />
    ) : null}
    </>
  );
};

export default Footer;
