import React, { FC } from "react";

const Target: FC = () => {
  return (
    <svg
      id="Group_257"
      data-name="Group 257"
      xmlns="http://www.w3.org/2000/svg"
      width="643"
      height="643"
      viewBox="0 0 643 643"
    >
      <g id="Group_234" data-name="Group 234" transform="translate(0 0)">
        <g
          id="Group_225"
          data-name="Group 225"
          transform="translate(194.713 194.713)"
        >
          <g id="Group_224" data-name="Group 224">
            <path
              id="Path_384"
              data-name="Path 384"
              d="M346.654,1069.518A126.787,126.787,0,1,1,473.442,942.73,126.93,126.93,0,0,1,346.654,1069.518Zm0-219.711a92.924,92.924,0,1,0,92.925,92.923A93.029,93.029,0,0,0,346.654,849.807Z"
              transform="translate(-219.868 -815.944)"
              fill="inherit"
            />
          </g>
        </g>
        <g
          id="Group_227"
          data-name="Group 227"
          transform="translate(262.108 262.108)"
        >
          <g id="Group_226" data-name="Group 226">
            <path
              id="Path_385"
              data-name="Path 385"
              d="M319.063,974.532a59.392,59.392,0,1,1,59.393-59.393A59.46,59.46,0,0,1,319.063,974.532Zm0-84.921a25.529,25.529,0,1,0,25.53,25.528A25.558,25.558,0,0,0,319.063,889.611Z"
              transform="translate(-259.672 -855.748)"
              fill="inherit"
            />
          </g>
        </g>
        <g
          id="Group_229"
          data-name="Group 229"
          transform="translate(130.02 130.022)"
        >
          <g id="Group_228" data-name="Group 228">
            <path
              id="Path_386"
              data-name="Path 386"
              d="M373.139,1160.693c-105.581,0-191.479-85.9-191.479-191.479s85.9-191.477,191.479-191.477,191.479,85.9,191.479,191.477S478.722,1160.693,373.139,1160.693Zm0-349.093c-86.909,0-157.615,70.705-157.615,157.614S286.23,1126.83,373.139,1126.83s157.616-70.707,157.616-157.615S460.049,811.6,373.139,811.6Z"
              transform="translate(-181.66 -777.737)"
              fill="inherit"
            />
          </g>
        </g>
        <g
          id="Group_231"
          data-name="Group 231"
          transform="translate(63.443 63.443)"
        >
          <g id="Group_230" data-name="Group 230">
            <path
              id="Path_387"
              data-name="Path 387"
              d="M400.4,1254.529c-142.292,0-258.056-115.764-258.056-258.058S258.1,738.415,400.4,738.415,658.453,854.179,658.453,996.471,542.689,1254.529,400.4,1254.529Zm0-482.25c-123.62,0-224.193,100.573-224.193,224.193S276.775,1220.665,400.4,1220.665s224.194-100.573,224.194-224.194S524.017,772.279,400.4,772.279Z"
              transform="translate(-142.339 -738.415)"
              fill="inherit"
            />
          </g>
        </g>
        <g id="Group_233" data-name="Group 233">
          <g id="Group_232" data-name="Group 232">
            <path
              id="Path_388"
              data-name="Path 388"
              d="M426.368,1343.945c-177.275,0-321.5-144.226-321.5-321.5s144.224-321.5,321.5-321.5,321.5,144.224,321.5,321.5S603.645,1343.945,426.368,1343.945Zm0-609.137c-158.6,0-287.636,129.034-287.636,287.636s129.035,287.638,287.636,287.638,287.637-129.034,287.637-287.638S584.972,734.808,426.368,734.808Z"
              transform="translate(-104.869 -700.945)"
              fill="inherit"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Target;
