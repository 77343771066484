import React, { FC } from "react";

const Waves: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="333.051"
      height="333.052"
      viewBox="0 0 333.051 333.052"
    >
      <rect
        id="Rectangle_253"
        data-name="Rectangle 253"
        width="333"
        height="83"
        transform="translate(0.051 249.885)"
        fill="#b92667"
      />
      <g id="Group_987" data-name="Group 987">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M285.911,629.486c2.935,3.118,4.707,5,4.707,9.3s-1.773,6.185-4.707,9.3c-3.468,3.685-8.22,8.733-8.22,18.162s4.752,14.476,8.22,18.161c2.935,3.117,4.707,5,4.707,9.3s-1.773,6.183-4.707,9.3c-3.468,3.684-8.22,8.732-8.22,18.161s4.752,14.476,8.22,18.16c2.935,3.118,4.707,5,4.707,9.3s-1.773,6.183-4.707,9.3c-3.468,3.685-8.22,8.733-8.22,18.161s4.751,14.478,8.22,18.164c2.935,3.118,4.707,5,4.707,9.3s-1.773,6.184-4.707,9.3c-3.468,3.685-8.22,8.732-8.22,18.161s4.752,14.477,8.22,18.162c2.935,3.117,4.707,5,4.707,9.3s-1.773,6.184-4.707,9.3c-3.469,3.685-8.22,8.733-8.22,18.161s4.752,14.477,8.22,18.163c2.935,3.116,4.707,5,4.707,9.3s-1.773,6.185-4.707,9.3c-2.488,2.643-5.635,5.988-7.216,11.148h14.553c.6-.722,1.3-1.461,2.075-2.287,2.681-2.847,6.123-6.509,7.551-12.374V907.627c-1.428-5.865-4.87-9.527-7.551-12.374-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c2.681-2.847,6.123-6.509,7.551-12.374V852.7c-1.428-5.865-4.87-9.528-7.551-12.374-2.935-3.117-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c2.681-2.847,6.123-6.508,7.551-12.373V797.774c-1.428-5.865-4.87-9.527-7.551-12.374-2.935-3.118-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c2.681-2.846,6.123-6.509,7.551-12.373V742.846c-1.428-5.864-4.87-9.526-7.551-12.372-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c2.681-2.846,6.123-6.508,7.551-12.372V687.926c-1.428-5.865-4.87-9.527-7.551-12.373-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c2.681-2.847,6.123-6.508,7.551-12.373V633c-1.428-5.865-4.87-9.527-7.551-12.374-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c.364-.386.743-.789,1.128-1.21H280.115a22.548,22.548,0,0,0-2.424,10.511C277.691,620.753,282.442,625.8,285.911,629.486Z"
          transform="translate(30.176 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M258.137,629.486c2.934,3.118,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.162s4.752,14.476,8.221,18.161c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.684-8.221,8.732-8.221,18.161s4.752,14.476,8.221,18.16c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.752,14.478,8.221,18.164c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.469,3.685-8.221,8.732-8.221,18.161s4.752,14.477,8.221,18.162c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.752,14.477,8.221,18.163c2.935,3.116,4.706,5,4.706,9.3s-1.773,6.185-4.706,9.3c-2.488,2.643-5.635,5.988-7.217,11.148h14.555c.6-.722,1.3-1.461,2.076-2.287,3.468-3.685,8.22-8.733,8.22-18.163s-4.752-14.476-8.22-18.161c-2.936-3.117-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c3.468-3.685,8.22-8.733,8.22-18.162s-4.751-14.477-8.22-18.162c-2.936-3.117-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.468-3.685,8.22-8.732,8.22-18.161s-4.751-14.476-8.22-18.162c-2.936-3.118-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c3.468-3.685,8.22-8.733,8.22-18.161s-4.752-14.476-8.22-18.16c-2.936-3.118-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.468-3.684,8.22-8.732,8.22-18.16s-4.752-14.476-8.22-18.16c-2.936-3.118-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.469-3.685,8.22-8.732,8.22-18.162s-4.752-14.477-8.22-18.162c-2.936-3.117-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c.364-.386.743-.789,1.127-1.21H252.34a22.547,22.547,0,0,0-2.424,10.511C249.916,620.753,254.668,625.8,258.137,629.486Z"
          transform="translate(27.158 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M230.363,629.486c2.934,3.118,4.705,5,4.705,9.3s-1.771,6.185-4.705,9.3c-3.469,3.685-8.221,8.733-8.221,18.162s4.752,14.476,8.221,18.161c2.935,3.117,4.705,5,4.705,9.3s-1.771,6.183-4.705,9.3c-3.469,3.684-8.221,8.732-8.221,18.161s4.752,14.476,8.221,18.16c2.935,3.118,4.705,5,4.705,9.3s-1.771,6.183-4.705,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.752,14.478,8.221,18.164c2.935,3.118,4.705,5,4.705,9.3s-1.771,6.184-4.705,9.3c-3.469,3.685-8.221,8.732-8.221,18.161s4.752,14.477,8.221,18.162c2.935,3.117,4.705,5,4.705,9.3s-1.771,6.184-4.705,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.752,14.477,8.221,18.163c2.935,3.116,4.705,5,4.705,9.3s-1.772,6.185-4.705,9.3c-2.489,2.643-5.636,5.988-7.217,11.148H237.7c.6-.722,1.3-1.461,2.074-2.287,3.468-3.685,8.221-8.733,8.221-18.163s-4.753-14.476-8.221-18.161c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.468-3.685,8.221-8.733,8.221-18.162s-4.752-14.477-8.221-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.468-3.685,8.221-8.732,8.221-18.161s-4.752-14.477-8.221-18.162c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.468-3.685,8.221-8.733,8.221-18.161s-4.753-14.477-8.221-18.161c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.468-3.685,8.221-8.732,8.221-18.16s-4.753-14.476-8.221-18.16c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.685,8.221-8.732,8.221-18.162s-4.753-14.477-8.221-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c.365-.386.743-.789,1.128-1.21H224.566a22.548,22.548,0,0,0-2.424,10.511C222.142,620.753,226.894,625.8,230.363,629.486Z"
          transform="translate(24.14 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M202.588,629.486c2.935,3.118,4.706,5,4.706,9.3s-1.771,6.185-4.706,9.3c-3.468,3.685-8.221,8.733-8.221,18.162s4.753,14.476,8.221,18.161c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.468,3.684-8.221,8.732-8.221,18.161s4.753,14.476,8.221,18.16c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.468,3.685-8.221,8.733-8.221,18.161s4.752,14.478,8.221,18.164c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.468,3.685-8.221,8.732-8.221,18.161s4.753,14.477,8.221,18.162c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.753,14.477,8.221,18.163c2.936,3.116,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-2.488,2.643-5.635,5.988-7.217,11.148h14.555c.6-.722,1.3-1.461,2.074-2.287,3.47-3.685,8.221-8.733,8.221-18.163s-4.751-14.476-8.221-18.161c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.162s-4.751-14.477-8.221-18.162c-2.934-3.117-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.161S215.469,789.086,212,785.4c-2.934-3.118-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.161s-4.751-14.476-8.221-18.16c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.684,8.221-8.732,8.221-18.16s-4.751-14.476-8.221-18.16c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.162s-4.751-14.477-8.221-18.162c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c.365-.386.743-.789,1.129-1.21H196.792a22.548,22.548,0,0,0-2.425,10.511C194.367,620.753,199.119,625.8,202.588,629.486Z"
          transform="translate(21.122 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M174.814,629.488c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.162s4.752,14.477,8.221,18.162c2.935,3.116,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.684-8.221,8.732-8.221,18.16s4.752,14.476,8.221,18.16c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.751,14.479,8.221,18.164c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.685-8.221,8.732-8.221,18.161s4.752,14.477,8.221,18.162c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.47,3.685-8.221,8.733-8.221,18.161s4.752,14.478,8.221,18.163c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-2.489,2.643-5.637,5.988-7.217,11.148h14.555c.6-.722,1.3-1.461,2.074-2.287,3.47-3.685,8.221-8.733,8.221-18.163s-4.751-14.476-8.221-18.161c-2.934-3.117-4.705-5-4.705-9.3s1.772-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.162s-4.751-14.477-8.221-18.162c-2.934-3.117-4.705-5-4.705-9.3s1.772-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.16s-4.751-14.477-8.221-18.163c-2.934-3.118-4.705-5-4.705-9.3s1.772-6.182,4.705-9.3c3.47-3.684,8.221-8.732,8.221-18.161s-4.751-14.476-8.221-18.16c-2.934-3.118-4.705-5-4.705-9.3s1.772-6.184,4.705-9.3c3.47-3.684,8.221-8.732,8.221-18.16s-4.751-14.476-8.221-18.16c-2.934-3.118-4.705-5-4.705-9.3s1.772-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.162s-4.751-14.477-8.221-18.162c-2.934-3.117-4.705-5-4.705-9.3s1.772-6.183,4.705-9.3c.365-.387.743-.791,1.13-1.211H169.018a22.547,22.547,0,0,0-2.425,10.511C166.593,620.753,171.344,625.8,174.814,629.488Z"
          transform="translate(18.104 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M147.039,629.486c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.185-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.162s4.751,14.476,8.22,18.161c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.469,3.684-8.22,8.732-8.22,18.161s4.751,14.476,8.22,18.16c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.161s4.751,14.478,8.22,18.164c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.469,3.685-8.22,8.732-8.22,18.161s4.751,14.477,8.22,18.162c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.161s4.751,14.477,8.22,18.163c2.936,3.116,4.706,5,4.706,9.3s-1.771,6.185-4.706,9.3c-2.488,2.643-5.635,5.988-7.215,11.148h14.552c.6-.722,1.3-1.461,2.075-2.287,3.469-3.685,8.221-8.733,8.221-18.163s-4.752-14.476-8.221-18.161c-2.936-3.117-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c3.469-3.685,8.221-8.733,8.221-18.162s-4.751-14.477-8.221-18.162c-2.934-3.117-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.469-3.685,8.221-8.732,8.221-18.161s-4.751-14.476-8.221-18.162c-2.934-3.118-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c3.469-3.685,8.221-8.733,8.221-18.161s-4.752-14.476-8.221-18.16c-2.936-3.118-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.469-3.684,8.221-8.732,8.221-18.16s-4.752-14.476-8.221-18.16c-2.936-3.118-4.706-5-4.706-9.3s1.771-6.184,4.706-9.3c3.47-3.685,8.221-8.732,8.221-18.162s-4.752-14.477-8.221-18.162c-2.936-3.117-4.706-5-4.706-9.3s1.771-6.183,4.706-9.3c.364-.386.743-.789,1.129-1.21H141.243a22.562,22.562,0,0,0-2.424,10.511C138.819,620.753,143.57,625.8,147.039,629.486Z"
          transform="translate(15.085 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M119.265,629.486c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-3.469,3.686-8.22,8.734-8.22,18.163s4.751,14.476,8.22,18.16c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.469,3.684-8.22,8.732-8.22,18.161s4.751,14.476,8.22,18.16c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.161s4.75,14.477,8.22,18.164c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.469,3.686-8.22,8.733-8.22,18.162s4.751,14.477,8.22,18.162c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.47,3.685-8.22,8.733-8.22,18.161s4.751,14.477,8.22,18.163c2.935,3.116,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-2.489,2.643-5.635,5.988-7.216,11.148H126.6c.6-.722,1.3-1.461,2.075-2.287,3.47-3.685,8.221-8.733,8.221-18.163s-4.751-14.476-8.221-18.161c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.162s-4.751-14.477-8.221-18.162c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.161s-4.751-14.476-8.221-18.163c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.161s-4.751-14.476-8.221-18.16c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.684,8.221-8.733,8.221-18.161s-4.751-14.475-8.221-18.159c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.162s-4.751-14.477-8.221-18.162c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c.365-.386.743-.789,1.129-1.21H113.469a22.548,22.548,0,0,0-2.424,10.511C111.045,620.753,115.795,625.8,119.265,629.486Z"
          transform="translate(12.067 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M91.491,629.486c2.934,3.118,4.706,5,4.706,9.3s-1.772,6.185-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.162s4.752,14.476,8.221,18.161c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.684-8.221,8.732-8.221,18.161s4.752,14.476,8.221,18.16c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.183-4.706,9.3c-3.469,3.685-8.221,8.733-8.221,18.161s4.751,14.478,8.221,18.164c2.935,3.118,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.469,3.685-8.221,8.732-8.221,18.161s4.752,14.477,8.221,18.162c2.935,3.117,4.706,5,4.706,9.3s-1.772,6.184-4.706,9.3c-3.47,3.685-8.221,8.733-8.221,18.161s4.752,14.477,8.221,18.163c2.935,3.116,4.706,5,4.706,9.3s-1.773,6.185-4.706,9.3c-2.489,2.643-5.635,5.988-7.217,11.148H98.828c.6-.722,1.3-1.461,2.074-2.287,3.47-3.685,8.221-8.733,8.221-18.163s-4.751-14.476-8.221-18.161c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.162s-4.751-14.477-8.221-18.162c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.161s-4.751-14.476-8.221-18.162c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c3.47-3.685,8.221-8.733,8.221-18.161s-4.751-14.476-8.221-18.16c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.684,8.221-8.732,8.221-18.16s-4.751-14.476-8.221-18.16c-2.935-3.118-4.705-5-4.705-9.3s1.771-6.184,4.705-9.3c3.47-3.685,8.221-8.732,8.221-18.162s-4.751-14.477-8.221-18.162c-2.935-3.117-4.705-5-4.705-9.3s1.771-6.183,4.705-9.3c.365-.386.743-.789,1.129-1.21H85.695a22.548,22.548,0,0,0-2.425,10.511C83.27,620.753,88.021,625.8,91.491,629.486Z"
          transform="translate(9.049 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M63.717,629.486c2.934,3.118,4.705,5,4.705,9.3s-1.769,6.185-4.705,9.3c-3.469,3.685-8.221,8.733-8.221,18.162s4.752,14.476,8.221,18.161c2.936,3.117,4.705,5,4.705,9.3s-1.769,6.183-4.705,9.3c-3.469,3.684-8.221,8.732-8.221,18.161s4.752,14.476,8.221,18.16c2.936,3.118,4.705,5,4.705,9.3s-1.769,6.183-4.705,9.3C60.248,761.62,55.5,766.668,55.5,776.1s4.751,14.478,8.221,18.164c2.936,3.118,4.705,5,4.705,9.3s-1.769,6.184-4.705,9.3c-3.469,3.685-8.221,8.732-8.221,18.161s4.752,14.477,8.221,18.162c2.936,3.117,4.705,5,4.705,9.3s-1.769,6.184-4.705,9.3c-3.47,3.685-8.221,8.733-8.221,18.161s4.752,14.477,8.221,18.163c2.936,3.116,4.705,5,4.705,9.3s-1.772,6.185-4.705,9.3c-2.489,2.643-5.636,5.988-7.217,11.148H71.053c.6-.722,1.3-1.461,2.075-2.287,3.469-3.685,8.221-8.733,8.221-18.163s-4.752-14.476-8.221-18.161c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.469-3.685,8.221-8.733,8.221-18.162s-4.752-14.477-8.221-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.685,8.221-8.732,8.221-18.161S76.6,789.086,73.128,785.4c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.469-3.685,8.221-8.733,8.221-18.161s-4.752-14.476-8.221-18.16c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.684,8.221-8.732,8.221-18.16s-4.752-14.476-8.221-18.16c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.685,8.221-8.732,8.221-18.162s-4.752-14.477-8.221-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c.365-.386.743-.789,1.129-1.21H57.921A22.534,22.534,0,0,0,55.5,611.324C55.5,620.753,60.247,625.8,63.717,629.486Z"
          transform="translate(6.031 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M35.942,629.486c2.934,3.118,4.706,5,4.706,9.3s-1.771,6.185-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.162s4.751,14.476,8.22,18.161c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.469,3.684-8.22,8.732-8.22,18.161s4.751,14.476,8.22,18.16c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.183-4.706,9.3c-3.469,3.685-8.22,8.733-8.22,18.161s4.75,14.478,8.22,18.164c2.936,3.118,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.469,3.685-8.22,8.732-8.22,18.161s4.751,14.477,8.22,18.162c2.936,3.117,4.706,5,4.706,9.3s-1.771,6.184-4.706,9.3c-3.47,3.685-8.22,8.733-8.22,18.161s4.751,14.477,8.22,18.163c2.936,3.116,4.706,5,4.706,9.3s-1.773,6.185-4.706,9.3c-2.488,2.643-5.635,5.988-7.216,11.148H43.279c.6-.722,1.3-1.461,2.075-2.287,3.469-3.685,8.22-8.733,8.22-18.163s-4.751-14.476-8.22-18.161c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.469-3.685,8.22-8.733,8.22-18.162s-4.751-14.477-8.22-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.685,8.22-8.732,8.22-18.161s-4.751-14.476-8.22-18.162c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c3.469-3.685,8.22-8.733,8.22-18.161s-4.751-14.476-8.22-18.16c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.684,8.22-8.732,8.22-18.16s-4.751-14.476-8.22-18.16c-2.935-3.118-4.706-5-4.706-9.3s1.772-6.184,4.706-9.3c3.469-3.685,8.22-8.732,8.22-18.162s-4.751-14.477-8.22-18.162c-2.935-3.117-4.706-5-4.706-9.3s1.772-6.183,4.706-9.3c.364-.386.743-.789,1.128-1.21H30.146a22.548,22.548,0,0,0-2.424,10.511C27.722,620.753,32.472,625.8,35.942,629.486Z"
          transform="translate(3.013 -600.813)"
          fill="#f50f77"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M0,613.1c.55,8.3,4.916,12.941,8.162,16.389,2.934,3.118,4.705,5,4.705,9.3s-1.771,6.185-4.705,9.3C4.917,651.537.55,656.178,0,664.48v3.543c.55,8.3,4.917,12.942,8.162,16.389,2.935,3.117,4.705,5,4.705,9.3s-1.771,6.183-4.705,9.3C4.917,706.459.55,711.1,0,719.4v3.543c.55,8.3,4.917,12.942,8.162,16.388,2.935,3.118,4.705,5,4.705,9.3s-1.771,6.183-4.705,9.3C4.917,761.383.55,766.022,0,774.325v3.543c.55,8.3,4.916,12.944,8.162,16.392,2.935,3.118,4.705,5,4.705,9.3s-1.771,6.184-4.705,9.3C4.917,816.311.55,820.95,0,829.252V832.8c.55,8.3,4.917,12.943,8.162,16.391,2.935,3.117,4.705,5,4.705,9.3s-1.771,6.184-4.705,9.3C4.916,871.237.55,875.877,0,884.18v3.543c.55,8.3,4.917,12.943,8.162,16.391,2.935,3.116,4.705,5,4.705,9.3s-1.772,6.185-4.705,9.3c-2.489,2.643-5.636,5.988-7.216,11.148H15.5c.6-.722,1.3-1.461,2.075-2.287,3.468-3.685,8.22-8.733,8.22-18.163s-4.752-14.476-8.22-18.161c-2.936-3.117-4.707-5-4.707-9.3s1.772-6.183,4.707-9.3c3.468-3.685,8.22-8.733,8.22-18.162s-4.752-14.477-8.22-18.162c-2.936-3.117-4.707-5-4.707-9.3s1.772-6.184,4.707-9.3c3.468-3.685,8.22-8.732,8.22-18.161s-4.752-14.476-8.22-18.162c-2.936-3.118-4.707-5-4.707-9.3s1.772-6.183,4.707-9.3c3.468-3.685,8.22-8.733,8.22-18.161s-4.752-14.476-8.22-18.16c-2.936-3.118-4.707-5-4.707-9.3s1.772-6.184,4.707-9.3c3.468-3.684,8.22-8.732,8.22-18.16s-4.752-14.476-8.22-18.16c-2.936-3.118-4.707-5-4.707-9.3s1.772-6.184,4.707-9.3c3.468-3.685,8.22-8.732,8.22-18.162s-4.752-14.477-8.22-18.162c-2.936-3.117-4.707-5-4.707-9.3s1.772-6.183,4.707-9.3c.364-.386.742-.789,1.128-1.21H2.366A22.051,22.051,0,0,0,0,609.553Z"
          transform="translate(0 -600.813)"
          fill="#f50f77"
        />
      </g>
    </svg>
  );
};

export default Waves;
