import React, { FC, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  MobileStepper,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTransition, animated } from "react-spring";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      position: "relative",
      overflow: "hidden",
      width: "100%",
      height: "auto",

      "&::before": {
        content: "open-quote",
        position: "absolute",
        top: 0,
        left: 0,
        fontSize: 40,
        color: "#CCCCCC",
        lineHeight: 1,
      },
    },
    subtitle: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      color: "#707070",
      fontWeight: "bold",
    },
    dots: {
      position: "absolute",
      paddingLeft: theme.spacing(5),
      left: 0,
      bottom: 0,
    },
  })
);

const DotsStepper = withStyles(() =>
  createStyles({
    root: {
      padding: 0,
    },
    dotActive: {
      backgroundColor: "#4B6162",
    },
  })
)(MobileStepper);

const PREDEFINED_SLIDES: { quote: string; author: string }[] = [
  {
    quote:
      "The world of HR is in a constant state of flux with big developments shaping things so quickly there is no time to keep up. FlourishZone offers an opportunity to understand change as it happens on an individual and system-wide level, which should be a game changer for not just HR but working life as a whole.",
    author: "Sarah Rice, Editor, The HR World",
  },
];

interface Props {
  slides?: { quote?: string; author?: string; id?: string }[];
}

const QuotesSlider: FC<Props> = ({ slides = PREDEFINED_SLIDES }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const classes = useStyles();
  const transitions = useTransition(activeSlide, p => p, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, height: 0, transform: "translate3d(-50%,0,0)" },
  });

  useEffect(() => {
    const id = setInterval(
      () => setActiveSlide(prev => (prev + 1) % slides.length),
      5000
    );

    return () => clearInterval(id);
  }, [slides.length]);

  return (
    <div className={classes.slider}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={{ ...props }}>
          <Typography variant="body2">{slides[item].quote}</Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {slides[item].author}
          </Typography>
        </animated.div>
      ))}
      <div className={classes.dots}>
        <DotsStepper
          variant="dots"
          steps={slides.length}
          position="static"
          activeStep={activeSlide}
          backButton={null}
          nextButton={null}
        />
      </div>
    </div>
  );
};

export default QuotesSlider;
