import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description_section: {
      marginTop: theme.spacing(6),
      overflow: "hidden",

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(10),
      },

      "& .MuiTypography-root": {
        textAlign: "justify",

        [theme.breakpoints.up("md")]: {
          textAlign: "initial",
        },
      },

      "& .MuiTypography-caption": {
        fontSize: "1rem",

        [theme.breakpoints.up("md")]: {
          fontSize: "initial",
        },
      },
    },
    description_item: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3),
      },
    },
    description_link: {
      color: "#6D7979",
      textDecoration: "none",
      fontWeight: "bold",
      display: "block",
      fontSize: "1.1rem",

      [theme.breakpoints.up("md")]: {
        fontSize: "initial",
      },
    },
    description_list: {
      listStyleType: "square",
      padding: 0,
      paddingLeft: theme.spacing(2),
      listStylePosition: "outside",

      "& li": {
        fontSize: "1rem",

        [theme.breakpoints.up("md")]: {
          fontSize: "initial",
        },
      },

      "& li:not(:last-of-type)": {
        marginBottom: theme.spacing(3),
      },
    },
    centered_title: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      marginBottom: theme.spacing(4),

      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6),
      },

      "& h4": {
        marginBottom: theme.spacing(1),
      },

      "& .MuiTypography-caption": {
        marginTop: theme.spacing(1),
        fontSize: "1rem",

        [theme.breakpoints.up("md")]: {
          fontSize: "initial",
        },
      },

      "& .MuiTypography-root": {
        textAlign: "center",
      },

      "& .MuiTypography-body2": {
        marginTop: theme.spacing(2),
        fontSize: "1rem",

        [theme.breakpoints.up("md")]: {
          fontSize: "initial",
        },
      },
    },
    order: {
      "&.left": {
        order: 1,
      },
      "&.right": {
        order: 2,
      },
    },
  })
);

export default useStyles;
