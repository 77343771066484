import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: "#4A4A4A",
    backgroundColor: "#F0F0F0",
    padding: theme.spacing(3),
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  Suny: {
    display: "flex",
    maxWidth: "30%",
    marginRight: theme.spacing(7),
  },

  brandInfo: {
    display: "flex",
    maxWidth: "30%",
    marginRight: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      maxWidth: "100%",
      marginBottom: theme.spacing(5),
      marginLeft: theme.spacing(3),
    },
  },
  footerLinks: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  brandName: {
    whiteSpace: "pre-line",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      alignItems: "center",
      padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      maxWidth: "50%",
      alignItems: "center",
      padding: `0 ${theme.spacing(4)}px 0 ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "32%",
      padding: 0,
    },
  },
  signupMessage: {
    display: "flex",
    marginBottom: theme.spacing(4),
  },
  signupForm: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  submitButton: {
    textTransform: "none",
    color: "#3498DB ",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    border: "none",
    borderRadius: "15px",
    height: theme.spacing(5),
    lineHeight: 1.5,
    backgroundColor: "#ffffff",
    "&:focus": {
      border: "2px solid #c0c0c0",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      borderRadius: "20px",
    },
  },
  input: {
    border: "1px solid #fafafa",
    boxShadow: "none",
    overflow: "hidden",
    height: theme.spacing(5),
    borderRadius: "20px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    "&:focus-within": {
      border: "2px solid #c0c0c0",
    },
  },
}));

export default useStyles;
