import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      backgroundColor: "#F4F4F4",
      padding: "10px 24px",
      borderRadius: 10,

      "& > span": {
        color: "#AFAFAF",
        fontSize: "1.5rem",
        marginRight: 28,
        alignSelf: "center",
      },

      "& > button": {
        width: 40,
        height: 40,

        "&:not(:last-of-type)": {
          marginRight: theme.spacing(2),
        },
      },
    },
  })
);

interface Props {
  url: string;
}

const ShareButtons: React.FC<Props> = (props: Props) => {
  const { url } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span>SHARE</span>
      <FacebookShareButton url={url}>
        <FacebookIcon size={40} bgStyle={{ fill: "#868686" }} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon size={40} bgStyle={{ fill: "#868686" }} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={40} bgStyle={{ fill: "#868686" }} />
      </LinkedinShareButton>
    </div>
  );
};

export default ShareButtons;
