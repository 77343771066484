import React, { FC, ReactNode } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import Waves from "./Waves";
import Target from "./Target";
import Angular from "./Angular";

type StyleProps = { color: string; position: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image_with_stand: {
      width: "100%",
      paddingRight: ({ position }: StyleProps) =>
        position === "right" ? "5%" : 0,
      paddingLeft: ({ position }: StyleProps) =>
        position === "left" ? "5%" : 0,
      paddingBottom: "10%",
      height: "100%",

      [theme.breakpoints.up("md")]: {
        paddingRight: ({ position }: StyleProps) =>
          position === "right" ? "10%" : 0,
        paddingLeft: ({ position }: StyleProps) =>
          position === "left" ? "10%" : 0,
      },
    },
    image_stand: {
      width: "100%",
      position: "relative",
      height: "100%",

      "& .adornment": {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        transform: ({ position }: StyleProps) =>
          position === "left"
            ? "translateX(100%) rotate(180deg)"
            : "translateX(-40%) rotate(180deg)",
        zIndex: 1,
        width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: "left",
        display: "block",

        [theme.breakpoints.up("md")]: {
          transform: ({ position }: StyleProps) =>
            position === "left"
              ? "translateX(70%) rotate(180deg)"
              : "translateX(-70%) rotate(180deg)",
        },

        "& svg": {
          height: "100%",
          fill: ({ color }: StyleProps) => color,
        },
      },

      "&::after": {
        content: "''",
        width: "100%",
        height: "100%",
        backgroundColor: ({ color }: StyleProps) => color,
        transform: ({ position }: StyleProps) =>
          position === "right" ? "translate(5%, 5%)" : "translate(-5%, 5%)",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: -1,
      },

      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        display: "block",
        minHeight: 300,
      },
    },
  })
);

const adornmentToImage: Record<"waves" | "target" | "angular", ReactNode> = {
  waves: <Waves />,
  target: <Target />,
  angular: <Angular />,
};

interface Props {
  src: string;
  standColor: string;
  standPosition?: "left" | "right";
  adornment?: "waves" | "target" | "angular";
}

const ImageWithStand: FC<Props> = ({
  src,
  standColor,
  standPosition = "right",
  adornment = "waves",
}) => {
  const classes = useStyles({ color: standColor, position: standPosition });
  return (
    <div className={classes.image_with_stand}>
      <div className={classes.image_stand}>
        <img src={src} alt="flourish zone" />
        <div className="adornment">{adornmentToImage[adornment]}</div>
      </div>
    </div>
  );
};

export default ImageWithStand;
