import React, { FC } from "react";

const TwoCircles: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="137.477"
      height="91.363"
      viewBox="0 0 137.477 91.363"
    >
      <g id="venn-diagram" transform="translate(0.5 -86)">
        <path
          id="Path_1842"
          data-name="Path 1842"
          d="M91.3,86.5a45.054,45.054,0,0,0-23.072,6.333,45.181,45.181,0,1,0,.014,77.689A45.176,45.176,0,1,0,91.3,86.5ZM45.181,172.864a41.218,41.218,0,1,1,23.057-7.076,41.166,41.166,0,0,1-5.417-63.86,2,2,0,1,0-2.765-2.888,45.156,45.156,0,0,0,4.45,69A40.927,40.927,0,0,1,45.181,172.864Zm46.114,0a40.927,40.927,0,0,1-19.324-4.825,45.134,45.134,0,0,0-.013-72.726A41.185,41.185,0,1,1,91.3,172.864Z"
          fill="#51ced2"
          stroke="#51ced2"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default TwoCircles;
