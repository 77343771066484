import ReactDOM from "react-dom";
import React, { FC, forwardRef, ReactNode, useRef } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { createStyles, Tab, Tabs } from "@material-ui/core";
import { useSpring, animated } from "react-spring";

import { appbarHeight } from "../../style/theme";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1200,
      width: "100%",
      top: appbarHeight / 2,
      position: "fixed",
      left: 0,
      backgroundColor: "rgba(0,0,0,0.18)",
      flexDirection: "column",
      justifyContent: "flex-end",
      padding: `${theme.spacing(1) * 0.5}px ${theme.spacing(4)}px`,
      minHeight: appbarHeight / 2 + 32,
      display: "none",

      [theme.breakpoints.up("md")]: {
        display: "flex",
      },

      "& a": {
        marginLeft: theme.spacing(3),
        fontWeight: "bold",
        textDecoration: "none",
        fontSize: theme.typography.fontSize * 1.1,
        color: theme.palette.common.white,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },

      "& .links": {
        marginTop: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
  })
);

const StyledTabs = withStyles((theme: Theme) => ({
  root: {
    height: "100%",
    alignItems: "center",
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 3,
    "& > span": {
      maxWidth: "80%",
      width: "100%",
      backgroundColor: "#393628",
    },
  },
}))(
  forwardRef((props: any, ref) => (
    <Tabs ref={ref} {...props} TabIndicatorProps={{ children: <span /> }} />
  ))
);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#AAACAD",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      minWidth: 72,
    },
    selected: {
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
  })
)((props: any) => <Tab disableRipple {...props} />);

const routes: { name: string; label: ReactNode; path: string }[] = [
  {
    name: "fz-for-you",
    label: (
      <span>
        Flourishzone for <b>You</b>
      </span>
    ),
    path: "/",
  },
  {
    name: "fz-for-coaches",
    label: (
      <span>
        Flourishzone for <b>Coaches</b>
      </span>
    ),
    path: "/fz-for-coaches",
  },
  {
    name: "fz-for-business",
    label: (
      <span>
        Flourishzone for <b>Business</b>
      </span>
    ),
    path: "/fz-for-business",
  },
  {
    name: "about-us",
    label: "About us",
    path: "/about-us",
  },
  {
    name: "blog",
    label: "Blog",
    path: "/blog",
  },
];

const Popper = forwardRef<any, { children?: ReactNode; show?: boolean }>(
  // @ts-ignore
  ({ children, show }, ref) => {
    const classes = useStyles();
    const props = useSpring({
      opacity: show ? 1 : 0,
      height: show ? "auto" : 0,
      ...{
        ...(show ? { minHeight: appbarHeight / 2 + 32 } : { minHeight: 0 }),
      },
    });

    if (typeof window !== "undefined") {
      const domElement = useRef<HTMLElement>(document.getElementById("root")!);

      // @ts-ignore
      if (children.props.route === "blog") {
        return null;
      }

      return ReactDOM.createPortal(
        <animated.div
          ref={ref}
          aria-label="nav-popper"
          style={props}
          className={classes.popper}
        >
          {children}
        </animated.div>,
        domElement.current
      );
    }

    return null;
  }
);

const PopperLinks: FC<{ route: string }> = ({ route }) => {
  switch (route) {
    case "fz-for-you":
      return (
        <div className="links">
          <AnchorLink to="/#live-your-life">
            Helps you grow
          </AnchorLink>
          <AnchorLink to="/#make-change">
            Makes improvement easier
          </AnchorLink>
          <AnchorLink to="/#feel-supported">
            Personalises to you
          </AnchorLink>
          <AnchorLink to="/#features-and-benefits">
            Benefits
          </AnchorLink>
          <AnchorLink to="/#download-the-app">
            Download the App
          </AnchorLink>
        </div>
      );
    case "fz-for-coaches":
      return (
        <div className="links">
          <AnchorLink to="/fz-for-coaches#productise">Productise</AnchorLink>
          <AnchorLink to="/fz-for-coaches#increase-impact">
            Increase impact
          </AnchorLink>
          <AnchorLink to="/fz-for-coaches#make-a-difference">
            Make a difference
          </AnchorLink>
          <AnchorLink to="/fz-for-coaches#method-builder">
            Method builder
          </AnchorLink>
          <AnchorLink to="/fz-for-coaches#contact-us">Contact us</AnchorLink>
        </div>
      );
      case "fz-for-business":
        return (
          <div className="links">
            <AnchorLink to="/fz-for-business#augmented-intelligence">
              Augmented intelligence
            </AnchorLink>
            <AnchorLink to="/fz-for-business#easier-to-excel">Make change easier</AnchorLink>
            <AnchorLink to="/fz-for-business#personalised-development">
              Hyper-personalised development
            </AnchorLink>
            <AnchorLink to="/fz-for-business#features-and-benefits">Benefits</AnchorLink>
            <AnchorLink to="/fz-for-business#what-clients-say">What our Clients say</AnchorLink>
            <AnchorLink to="/fz-for-business#awards">Awards</AnchorLink>
            <AnchorLink to="/fz-for-business#contact-us">Contact us</AnchorLink>
          </div>
        );
      case "about-us":
      return (
        <div className="links">
          <AnchorLink to="/about-us#about-us">About us</AnchorLink>
          <AnchorLink to="/about-us#our-vision">Our vision</AnchorLink>
          <AnchorLink to="/about-us#our-team">Our team</AnchorLink>
          <AnchorLink to="/about-us#contact-us">Contact us</AnchorLink>
        </div>
      );
    default:
      return null;
  }
};

const PublicNavTabs: FC = () => {
  const { pathname } = useLocation();
  const tabsRef = useRef<HTMLButtonElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  const normalizedPathname = pathname.split("/")[1] || "fz-for-you";
  return (
    <>
      <StyledTabs id="public-nav-tabs" ref={tabsRef} value={normalizedPathname}>
        {routes.map(route => (
          <StyledTab
            label={route.label}
            value={route.name}
            component={Link}
            to={route.path}
            aria-label={route.name}
            key={route.name}
          />
        ))}
      </StyledTabs>
      
      {normalizedPathname !== 'terms' ? (
              <Popper ref={popperRef} show>
              <PopperLinks route={normalizedPathname} />
            </Popper>
      ) : null}
    </>
  );
};

export default PublicNavTabs;
