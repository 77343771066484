import React, {useState} from "react";
import { makeStyles, Theme, withStyles, Typography, Button } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        backgroundColor: 'black',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            padding: 10
        }
    },
    content: {
        padding: 20
    },
    text: {
        color: 'white'
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        height: 40,
        minWidth: 110,
        margin: '0px 5px'
    }
}));

const PrimaryButton = withStyles(() => ({
    root: {
      color: 'white',
      border: '1px solid white',
      backgroundColor: 'black',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'white',
        color: 'black'
      },
    },
  }))(Button);

  const SecondaryButton = withStyles(() => ({
    root: {
        color: 'black',
        border: '1px solid black',
        backgroundColor: 'white',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'black',
          color: 'white'
        },
    },
  }))(Button);

  const LinkButton = withStyles(() => ({
    root: {
        color: 'white',
        borderBottom: '1px solid black',
        backgroundColor: 'black',
        borderRadius: 0,
        '&:hover': {
          borderBottom: '1px solid white',
          backgroundColor: 'black',
        },
    },
  }))(Button);


const CookieBanner: React.FC = () => {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  const acceptCookies = () => {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + (30*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();

    document.cookie = 'AcceptCookies' + "=" + (true || "")  + expires + "; path=/";
    setShow(false);
  }
  const rejectCookies = () => {
    //   What do when reject?
    setShow(false);
  }

  if(!show) {
      return null
  }

  return (
    <div className={classes.container}>
     <div className={classes.content}>
        <Typography variant="body1" className={classes.text}>
        This website uses cookies that help the website to function and also track how you interact with our website. By clicking “Allow All” you agree to the storing of cookies on your device.
        </Typography>
     </div>
     <div className={classes.buttons}>
     <PrimaryButton className={classes.button} variant="contained" onClick={() => acceptCookies()}>Accept All</PrimaryButton>
     <SecondaryButton className={classes.button} variant="contained" onClick={() => rejectCookies()}>Reject All</SecondaryButton>
     <LinkButton className={classes.button} variant="contained" href="./privacy-policy">Read More</LinkButton>
     </div>
    </div>
  );
};

export default CookieBanner;
