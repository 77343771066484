import { makeStyles, Theme } from "@material-ui/core/styles";

import { appbarHeight } from "../../style/theme";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: appbarHeight,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    background: "transparent",
  },
  container: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    padding: `0 ${theme.spacing(4)}px`,
    borderBottomRightRadius: "30px",
    boxShadow: theme.shadows[2],

    display: "flex",
    alignItems: "center",
  },
  logo: {
    display: "inline-block",
    objectFit: "cover",
    width: "100px",
    height: "35px",
    marginRight: theme.spacing(4),
  },
  version: {
    color: theme.palette.common.white,
    backgroundColor: "orange",
    borderRadius: "10px",
    display: "inline-block",
    height: "35px",
    width: "150px",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginRight: theme.spacing(4),
    textAlign: "center",
  },
  versionInfo: {
    color: "#404040",
    display: "inline-block",
    height: "35px",
    fontSize: "1rem",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px 0`,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  iconBlock: {
    color: "#404040",
    marginLeft: theme.spacing(2),
    display: "inline-block",

    "& svg": {
      width: 32,
      height: 32,
    },

    "&.power_button": {
      marginRight: -theme.spacing(1) * 1.5,
      "&:hover": {
        "& svg": {
          stroke: "#3cc9cd",
        },
      },
    },
  },
  icon: {
    color: "inherit",
    fontSize: "32px",
    "&:hover": {
      color: "#3cc9cd",
    },

    "& .hide-for-sm": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  notificationsPopup: {
    position: "absolute",
    top: appbarHeight + 6,
    right: 0,
    display: "block",
    width: "320px",
    height: "300px",
    borderRadius: 10,
    fontSize: "0.875rem",
    color: "#404040",
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    boxSizing: "border-box",
    zIndex: 1000,
  },
  notificationsList: {
    width: "100%",
    maxWidth: "360px",
    backgroundColor: "inherit",
    overflow: "auto",
    maxHeight: "300px",
  },
  inline: {
    display: "inline",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#404040",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  feedback: {
    color: "orange",
  },
  badge: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  actions: {
    marginLeft: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flexGrow: 1,
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: "bold",
  }
}));

export default useStyles;
