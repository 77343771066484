import React, { FC, ReactNode } from "react";
import clsx from "clsx";
import {
  createStyles,
  Grid,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import RoundedWhiteButton from "../RoundedWhiteButton";
import useStyles from "./styles";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: 3,
    "& > span": {
      maxWidth: "80%",
      width: "100%",
      backgroundColor: "#393628",
    },
  },
})((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#AAACAD",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(17),
      marginRight: theme.spacing(1),
    },
    selected: {
      color: theme.palette.common.black,
      fontWeight: "bold",
    },
  })
)((props: any) => <Tab disableRipple {...props} />);

interface Props {
  img: string;
  title: ReactNode;
  subtitle?: string;
  tab?: 0 | 1 | 2;
  hideSignUp?: boolean;
  hideTabs?: boolean;
  actionLabel?: string;
}

const HomeHero: FC<Props> = ({
  img,
  title,
  subtitle,
  tab = 0,
  hideSignUp,
  hideTabs,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.home_hero, "home_hero")}>
      <div className="img_container">
        <img src={img} alt="flourish zone" />
        <Grid container className={classes.cta} justify="center">
          <Grid item xs={11} md={10} lg={10}>
            <Typography variant="h2">
              <b>{title}</b>
            </Typography>
            <Typography variant="subtitle1">
              <b>{subtitle ?? "Together We Flourish"}</b>
            </Typography>
            {!hideSignUp && (
              <RoundedWhiteButton
                variant="contained"
                size="large"
                component={OutboundLink}
                href="https://portal.flourish.zone/register"
              >
                Get it free
              </RoundedWhiteButton>
            )}
          </Grid>
        </Grid>
      </div>
      {!hideTabs && (
        <Grid container justify="center" className={classes.tabs_container}>
          <Grid item xs={11} md={10}>
            <StyledTabs value={tab}>
              <StyledTab
                label={
                  <span>
                    Flourishzone for <b>You</b>
                  </span>
                }
                component={Link}
                to="/"
              />
              <StyledTab
                label={
                  <span>
                    Flourishzone for <b>Coaches</b>
                  </span>
                }
                component={Link}
                to="/fz-for-coaches"
              />
              <StyledTab
                label={
                  <span>
                    Flourishzone for <b>Business</b>
                  </span>
                }
                component={Link}
                to="/fz-for-business"
              />
            </StyledTabs>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default HomeHero;
