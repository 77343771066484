import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    dark_orange: {
      color: "#E17600",
    },
    dark_blue: {
      color: "#426788",
    },
    cyan: {
      color: "#38C1C4",
    },
  })
);

export default useStyles;
