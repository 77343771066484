import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    home_hero: {
      "& .img_container": {
        height: 450,
        position: "relative",

        [theme.breakpoints.up("md")]: {
          height: 650,
        },

        "& img": {
          width: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "top",
          borderBottomLeftRadius: "80% 45%",
          borderBottomRightRadius: "90% 80%",
          filter: "brightness(80%)",

          [theme.breakpoints.up("md")]: {
            borderBottomLeftRadius: "45% 45%",
            borderBottomRightRadius: "80% 100%",
          },
        },
      },
    },
    cta: {
      position: "absolute",
      left: 0,
      right: 0,
      width: "100%",
      bottom: 0,
      marginBottom: theme.spacing(11),
      zIndex: 1,

      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(12),
      },

      "& .MuiTypography-h2, .MuiTypography-subtitle1": {
        color: theme.palette.common.white,
      },

      "& .MuiTypography-subtitle1": {
        marginBottom: theme.spacing(3),
        width: "80%",

        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(5),
        },
      },

      "& .MuiTypography-h2": {
        whiteSpace: "pre-wrap",
      },

      "& button": {
        minWidth: 170,
      },
    },
    tabs_container: {
      marginTop: theme.spacing(3),
      display: "none",

      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
  })
);

export default useStyles;
