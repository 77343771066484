import React, { FC } from "react";

const TwentyFourHourIcon: FC = () => {
  return (
    <svg
      id="_24-hours"
      data-name="24-hours"
      xmlns="http://www.w3.org/2000/svg"
      width="104.766"
      height="118.629"
      viewBox="0 0 104.766 118.629"
    >
      <g id="Group_1495" data-name="Group 1495">
        <path
          id="Path_5764"
          data-name="Path 5764"
          d="M57.712,199.891H37.23a13.367,13.367,0,0,1,6.98-8.539l6.8-3.4A12.115,12.115,0,0,0,45.6,165H41.914A12.128,12.128,0,0,0,29.8,177.115v1.842h6.978v-1.842a5.142,5.142,0,0,1,5.136-5.136H45.6a5.136,5.136,0,0,1,2.3,9.731l-6.8,3.4A20.314,20.314,0,0,0,29.8,203.38v3.489H57.712Z"
          transform="translate(-29.799 -126.62)"
          fill="#51ced2"
        />
        <path
          id="Path_5765"
          data-name="Path 5765"
          d="M197.244,182.445h-4.809a5.665,5.665,0,0,1-5.659-5.659V165H179.8v11.787a12.651,12.651,0,0,0,12.637,12.637h4.809v17.445h6.978V165h-6.978Z"
          transform="translate(-144.908 -126.62)"
          fill="#51ced2"
        />
        <path
          id="Path_5766"
          data-name="Path 5766"
          d="M378.6,206.747,391.642,165H364.8v6.978h17.352l-10.215,32.687Z"
          transform="translate(-286.876 -126.62)"
          fill="#51ced2"
        />
        <path
          id="Path_5767"
          data-name="Path 5767"
          d="M314.8,240h10.467v6.978H314.8Z"
          transform="translate(-248.506 -184.175)"
          fill="#51ced2"
        />
        <path
          id="Path_5768"
          data-name="Path 5768"
          d="M239.8,0h6.978V13.956H239.8Z"
          transform="translate(-190.952)"
          fill="#51ced2"
        />
        <path
          id="Path_5769"
          data-name="Path 5769"
          d="M0,0H6.978V9.3H0Z"
          transform="translate(33.017 7.648) rotate(-15.009)"
          fill="#51ced2"
        />
        <path
          id="Path_5770"
          data-name="Path 5770"
          d="M0,0H6.978V9.3H0Z"
          transform="matrix(0.866, -0.5, 0.5, 0.866, 18.494, 14.654)"
          fill="#51ced2"
        />
        <path
          id="Path_5771"
          data-name="Path 5771"
          d="M0,0H6.978V9.3H0Z"
          transform="translate(6.279 25.173) rotate(-45)"
          fill="#51ced2"
        />
        <path
          id="Path_5772"
          data-name="Path 5772"
          d="M0,0H9.3V6.978H0Z"
          transform="translate(62.527 14.834) rotate(-74.991)"
          fill="#51ced2"
        />
        <path
          id="Path_5773"
          data-name="Path 5773"
          d="M0,0H9.3V6.978H0Z"
          transform="matrix(0.5, -0.866, 0.866, 0.5, 75.482, 19.226)"
          fill="#51ced2"
        />
        <path
          id="Path_5774"
          data-name="Path 5774"
          d="M0,0H9.3V6.978H0Z"
          transform="translate(86.864 26.826) rotate(-45)"
          fill="#51ced2"
        />
        <path
          id="Path_5775"
          data-name="Path 5775"
          d="M239.8,450h6.978v13.956H239.8Z"
          transform="translate(-190.952 -345.327)"
          fill="#51ced2"
        />
        <path
          id="Path_5776"
          data-name="Path 5776"
          d="M0,0H9.3V6.978H0Z"
          transform="translate(33.028 110.992) rotate(-74.991)"
          fill="#51ced2"
        />
        <path
          id="Path_5777"
          data-name="Path 5777"
          d="M0,0H9.3V6.978H0Z"
          transform="matrix(0.5, -0.866, 0.866, 0.5, 18.492, 103.974)"
          fill="#51ced2"
        />
        <path
          id="Path_5778"
          data-name="Path 5778"
          d="M0,0H9.3V6.978H0Z"
          transform="translate(6.272 93.448) rotate(-45)"
          fill="#51ced2"
        />
        <path
          id="Path_5779"
          data-name="Path 5779"
          d="M0,0H6.978V9.3H0Z"
          transform="translate(62.536 103.786) rotate(-15.009)"
          fill="#51ced2"
        />
        <path
          id="Path_5780"
          data-name="Path 5780"
          d="M0,0H6.978V9.3H0Z"
          transform="matrix(0.866, -0.5, 0.5, 0.866, 75.481, 99.402)"
          fill="#51ced2"
        />
        <path
          id="Path_5781"
          data-name="Path 5781"
          d="M0,0H6.978V9.3H0Z"
          transform="translate(86.857 91.812) rotate(-45)"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default TwentyFourHourIcon;
