import React, { FC } from "react";

const RewardIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118.736"
      height="102.212"
      viewBox="0 0 118.736 102.212"
    >
      <g id="award" transform="translate(0.529 0.5)">
        <path
          id="Path_5761"
          data-name="Path 5761"
          d="M103.887,60.968a13.806,13.806,0,0,0,13.79-13.79V37.416h-9.761a13.806,13.806,0,0,0-13.79,13.79v5.375h0a35.159,35.159,0,0,1-9.65,24.22A26.292,26.292,0,0,0,69.764,62.387a15.535,15.535,0,1,0-21.853,0A26.291,26.291,0,0,0,33.2,80.8a35.159,35.159,0,0,1-9.65-24.221V51.206a13.806,13.806,0,0,0-13.79-13.79H0v9.761a13.806,13.806,0,0,0,13.79,13.79h3.093A41.886,41.886,0,0,0,18.2,67.889,13.7,13.7,0,0,0,8.018,70.447L.17,76.251l5.8,7.848a13.8,13.8,0,0,0,19.288,2.889L27.8,85.112a42.577,42.577,0,0,0,5.108,4.716,13.728,13.728,0,0,0-6.08,7.078l-3.474,9.122,9.122,3.474a13.788,13.788,0,0,0,17.8-7.979l1.291-3.39a42.338,42.338,0,0,0,14.556,0l1.291,3.391A13.788,13.788,0,0,0,85.2,109.5l9.122-3.474-3.474-9.122a13.729,13.729,0,0,0-6.082-7.079,42.545,42.545,0,0,0,5.109-4.716l2.538,1.877A13.8,13.8,0,0,0,111.7,84.1l5.8-7.848-7.848-5.8a13.7,13.7,0,0,0-10.182-2.558,41.92,41.92,0,0,0,1.314-6.922h3.1ZM21.162,81.444A6.9,6.9,0,0,1,11.518,80l-1.7-2.3,2.3-1.7a6.895,6.895,0,0,1,9.644,1.444l1.7,2.3ZM43.826,99.069a6.9,6.9,0,0,1-8.9,3.99l-2.678-1.02,1.02-2.678a6.9,6.9,0,0,1,8.9-3.99l2.678,1.02Zm38.924,3.99a6.9,6.9,0,0,1-8.9-3.99l-1.02-2.678,2.678-1.02a6.9,6.9,0,0,1,8.9,3.99l1.02,2.678Zm22.81-27.067,2.3,1.7-1.7,2.3a6.9,6.9,0,0,1-9.644,1.444l-2.3-1.7,1.7-2.3a6.894,6.894,0,0,1,9.643-1.444Zm-4.538-24.785a6.9,6.9,0,0,1,6.9-6.9h2.866v2.866a6.9,6.9,0,0,1-6.9,6.9h-2.866Zm-50.824.15A8.639,8.639,0,1,1,58.837,60,8.649,8.649,0,0,1,50.2,51.357ZM6.9,47.177V44.312H9.761a6.9,6.9,0,0,1,6.9,6.9v2.866H13.79A6.9,6.9,0,0,1,6.9,47.177ZM39.541,86.108a19.3,19.3,0,0,1,38.594,0,35.209,35.209,0,0,1-38.594,0Z"
          transform="translate(0 -35.822)"
          fill="#51ced2"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          id="Path_5762"
          data-name="Path 5762"
          d="M159.9,385.923h44.168v6.9H159.9Z"
          transform="translate(-123.15 -305.453)"
          fill="#51ced2"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          id="Path_5763"
          data-name="Path 5763"
          d="M183.987,446.166h33.1v6.9h-33.1Z"
          transform="translate(-141.697 -351.849)"
          fill="#51ced2"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default RewardIcon;
