import React from "react";

import { Link } from "@material-ui/core";

import useStyles from "./styles";
import PublicNavTabs from "./PublicNavTabs";

interface Props {
  noSub?: boolean;
}

const Header: React.FC<Props> = ({ noSub }) => {
  const classes = useStyles();
  const showSub = !noSub;
  return (
    <>
      <header className={classes.header}>
        <div className={classes.container}>
          <Link href="/">
            <img
              src="/logo.png"
              alt="FlourishZone logo"
              className={classes.logo}
            />
          </Link>

          <>
            <div className={classes.version}>          
            <Link className={classes.link} href="https://portal.flourish.zone">
              SIGN IN
            </Link></div>
          </>

          <div className={classes.spacer} />
          {showSub ? (
            <PublicNavTabs />
          ) : null}
        </div>
      </header>
    </>
  );
};

export default Header;
