import React, { FC } from "react";
import clsx from "clsx";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    award_item: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "flex-start",

      [theme.breakpoints.up("md")]: {
        alignSelf: "initial",
      },

      "&.cyan .MuiTypography-root": {
        color: "#29948B",
      },

      "&.purple .MuiTypography-root": {
        color: "#7A2B8B",
      },

      "& .MuiTypography-body1": {
        marginTop: theme.spacing(1),
        lineHeight: 1,
      },

      "& .MuiTypography-body1:last-of-type": {
        marginTop: theme.spacing(1),
      },
    },
  })
);

interface Props {
  title: string;
  description: string;
  year: string;
  color?: "purple" | "cyan";
}

const AwardItem: FC<Props> = ({
  title,
  description,
  year,
  color = "purple",
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.award_item, color)}>
      <Typography variant="subtitle1">
        <b>{title}</b>
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1">
        <b>{year}</b>
      </Typography>
    </div>
  );
};

export default AwardItem;
