import React, { FC, PropsWithChildren } from "react";
import ReactMarkdown from "react-markdown";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";

type StyleProps = { accentColor: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    markdown_description: {
      "& .MuiTypography-body1": {
        marginTop: theme.spacing(3),
      },
      "& .MuiTypography-h5:last-of-type": {
        color: ({ accentColor }: StyleProps) => accentColor,
      },
      "& ul, ol": {
        listStyleType: "square",
        padding: 0,
        paddingLeft: theme.spacing(2),
        listStylePosition: "outside",
        "& li": {
          fontSize: "1rem",

          [theme.breakpoints.up("md")]: {
            fontSize: "initial",
          },
        },

        "& li:not(:last-of-type)": {
          marginBottom: theme.spacing(3),
        },
      },
    },
  })
);

interface Props {
  description: string;
  accentColor?: string;
}

const MarkdownDescription: FC<Props> = ({
  description,
  accentColor = "#F4D525",
}) => {
  const classes = useStyles({ accentColor });

  const renderers = {
    heading: (props: PropsWithChildren<any>) => (
      <Typography variant="h5" children={props.children} />
    ),
    paragraph: (props: PropsWithChildren<any>) => (
      <Typography variant="body1" children={props.children} />
    ),
  };

  return (
    <div className={classes.markdown_description}>
      <ReactMarkdown renderers={renderers}>{description}</ReactMarkdown>
    </div>
  );
};

export default MarkdownDescription;
