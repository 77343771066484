import React, { FC } from "react";

const Corners: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="340"
      height="323.632"
      viewBox="0 0 340 323.632"
    >
      <g
        id="Group_292"
        data-name="Group 292"
        transform="translate(-5.527 -0.009)"
      >
        <rect
          id="Rectangle_254"
          data-name="Rectangle 254"
          width="340"
          height="78"
          transform="translate(5.527 245.641)"
          fill="#78a600"
        />
        <g
          id="Group_1033"
          data-name="Group 1033"
          transform="translate(94.303 161.629)"
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M83.621,80.872l-39.6-40.677L83.152,0H39.129L0,40.194,39.6,80.871,0,121.547l39.131,40.2H83.152l-39.13-40.2Z"
            transform="translate(0 0)"
            fill="#97cc0c"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M83.62,80.872,44.023,40.2,83.152,0H39.13L0,40.2,39.6,80.872,0,121.548l39.13,40.2H83.152l-39.13-40.2Z"
            transform="translate(78.261 0)"
            fill="#97cc0c"
          />
        </g>
        <g
          id="Group_1034"
          data-name="Group 1034"
          transform="translate(94.304 0.009)"
        >
          <path
            id="Path_14-2"
            data-name="Path 14"
            d="M83.621,81.319l-39.6-40.9L83.152,0H39.129L0,40.417l39.6,40.9L0,122.22l39.131,40.42H83.152L44.022,122.22Z"
            transform="translate(0 0)"
            fill="#97cc0c"
          />
          <path
            id="Path_15-2"
            data-name="Path 15"
            d="M83.62,81.319l-39.6-40.9L83.152,0H39.13L0,40.418l39.6,40.9L0,122.22l39.13,40.419H83.152l-39.13-40.419Z"
            transform="translate(78.261 0)"
            fill="#97cc0c"
          />
        </g>
      </g>
      <g
        id="Group_1060"
        data-name="Group 1060"
        transform="translate(-5.527 -0.009)"
      >
        <g
          id="Group_1033-2"
          data-name="Group 1033"
          transform="translate(82.303 161.629)"
        >
          <path
            id="Path_14-3"
            data-name="Path 14"
            d="M83.621,80.872l-39.6-40.677L83.152,0H39.129L0,40.194,39.6,80.871,0,121.547l39.131,40.2H83.152l-39.13-40.2Z"
            transform="translate(0 0)"
            fill="#97cc0c"
          />
          <path
            id="Path_15-3"
            data-name="Path 15"
            d="M83.62,80.872,44.023,40.2,83.152,0H39.13L0,40.2,39.6,80.872,0,121.548l39.13,40.2H83.152l-39.13-40.2Z"
            transform="translate(78.261 0)"
            fill="#97cc0c"
          />
        </g>
        <g
          id="Group_1034-2"
          data-name="Group 1034"
          transform="translate(82.304 0.009)"
        >
          <path
            id="Path_14-4"
            data-name="Path 14"
            d="M83.621,81.319l-39.6-40.9L83.152,0H39.129L0,40.417l39.6,40.9L0,122.22l39.131,40.42H83.152L44.022,122.22Z"
            transform="translate(0 0)"
            fill="#97cc0c"
          />
          <path
            id="Path_15-4"
            data-name="Path 15"
            d="M83.62,81.319l-39.6-40.9L83.152,0H39.13L0,40.418l39.6,40.9L0,122.22l39.13,40.419H83.152l-39.13-40.419Z"
            transform="translate(78.261 0)"
            fill="#97cc0c"
          />
        </g>
      </g>
    </svg>
  );
};

export default Corners;
