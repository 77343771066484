import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import SunyIcon from "./icons/SunyIcon";
import ClockIcon from "./icons/ClockIcon";
import FileIcon from "./icons/FileIcon";
import MegaphoneIcon from "./icons/MegaphoneIcon";
import PillsIcon from "./icons/PillsIcon";
import PrayIcon from "./icons/PrayIcon";
import TargetIcon from "./icons/TargetIcon";
import TwoCirclesIcon from "./icons/TwoCirclesIcon";
import UploadIcon from "./icons/UploadIcon";
import MountainIcon from "./icons/MountainIcon";
import TwentyFourHourIcon from "./icons/TwentyFourHourIcon";
import RewardIcon from "./icons/RewardIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    benefit_item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      flexShrink: 0,
      height: "100%",

      "& svg": {
        height: 100,
        minHeight: 100,
      },

      "& h5": {
        textAlign: "center",
        minHeight: 60,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
      },

      "& .MuiTypography-body2": {
        marginTop: "auto",
        justifySelf: "flex-end",
        fontSize: "1rem",
        textAlign: "left",
        minHeight: 50,
        width: "100%",
        height: "100%",

        [theme.breakpoints.up("md")]: {
          fontSize: "initial",
        },
      },
    },
  })
);

interface Props {
  title: string;
  description: string;
  icon:
    | "suny"
    | "clock_settings"
    | "hands_heart"
    | "layout"
    | "pills"
    | "mountain"
    | "megaphone"
    | "publish"
    | "venn"
    | "target"
    | "twentyfourhr"
    | "reward";
}

const iconMap = {
  suny: <SunyIcon />,
  clock_settings: <ClockIcon />,
  hands_heart: <PrayIcon />,
  layout: <FileIcon />,
  pills: <PillsIcon />,
  mountain: <MountainIcon />,
  megaphone: <MegaphoneIcon />,
  publish: <UploadIcon />,
  venn: <TwoCirclesIcon />,
  target: <TargetIcon />,
  twentyfourhr: <TwentyFourHourIcon />,
  reward: <RewardIcon />,
};

const BenefitItem: FC<Props> = ({ title, description, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.benefit_item}>
      {iconMap[icon]}
      <Typography variant="h5">
        <b>{title}</b>
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </div>
  );
};

export default BenefitItem;
