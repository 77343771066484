import React, { FC } from "react";

const MountainIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118.993"
      height="118.998"
      viewBox="0 0 118.993 118.998"
    >
      <g id="goal" transform="translate(-0.01)">
        <path
          id="Path_1917"
          data-name="Path 1917"
          d="M118.386,113.295,91.18,72.486a3.668,3.668,0,0,0-6.1,0l-3.558,5.337L65.1,53.2V30.439H77.015s0,9.992.006,10.076a5.641,5.641,0,0,0,5.7,5.325h23.527a3.668,3.668,0,0,0,3.185-5.488l-5.509-9.641a.182.182,0,0,1,0-.18l5.509-9.641a3.668,3.668,0,0,0-3.185-5.488H88.2V9.444A5.6,5.6,0,0,0,82.609,3.85H65.1V3.668A3.673,3.673,0,0,0,61.432,0h-3.85a3.673,3.673,0,0,0-3.668,3.668v6.5a1.743,1.743,0,0,0,3.486,0v-6.5a.182.182,0,0,1,.182-.182h3.85a.182.182,0,0,1,.182.182V48.415a3.671,3.671,0,0,0-4.214,0V17.145a1.743,1.743,0,1,0-3.486,0V53.2L37.495,77.823l-3.558-5.337a3.668,3.668,0,0,0-6.1,0L.627,113.295A3.668,3.668,0,0,0,3.679,119H25.217a1.743,1.743,0,1,0,0-3.486H16.559L41.448,78.178l10.173-5.086,6.839,5.129a1.743,1.743,0,0,0,2.092,0l6.839-5.129,10.174,5.087,24.889,37.334h-70.2a1.743,1.743,0,1,0,0,3.486h83.079a3.668,3.668,0,0,0,3.052-5.7ZM82.609,30.439a2.107,2.107,0,1,1,0,4.214,5.59,5.59,0,0,0-2.107.416V30.439Zm23.635-11.551a.182.182,0,0,1,.158.273l-5.509,9.64a3.682,3.682,0,0,0,0,3.64l5.509,9.641a.182.182,0,0,1-.158.273H82.718a2.188,2.188,0,0,1-2.214-2,2.108,2.108,0,0,1,2.1-2.212A5.6,5.6,0,0,0,88.2,32.546V18.888ZM82.609,7.337a2.109,2.109,0,0,1,2.107,2.107V27.366a5.563,5.563,0,0,0-2.107-.413H65.1V7.337ZM3.679,115.512a.182.182,0,0,1-.152-.283L30.733,74.42a.182.182,0,0,1,.3,0L35.4,80.965,12.369,115.512ZM67.987,69.491a1.743,1.743,0,0,0-1.826.165l-6.655,4.991-6.655-4.991a1.744,1.744,0,0,0-1.825-.165l-5.68,2.84L59.355,51.318a.181.181,0,0,1,.3,0L73.667,72.331Zm47.508,45.925a.163.163,0,0,1-.16.1h-8.69L83.613,80.965l4.364-6.546a.182.182,0,0,1,.3,0l27.206,40.809a.164.164,0,0,1,.009.187Z"
          transform="translate(0)"
          fill="#51ced2"
        />
      </g>
    </svg>
  );
};

export default MountainIcon;
