import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    golden: {
      color: "#D9BC12",
    },
    silver: {
      color: "#817E7E",
    },
    orange: {
      color: "#FE8100",
    },
    reviews_section: {
      "& .MuiTypography-h4.MuiTypography-root": {
        textAlign: "center",

        [theme.breakpoints.up("md")]: {
          textAlign: "right",
        },
      },
    },
    awards_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .innovate_uk_logo": {
        maxWidth: 270,
        height: "auto",
      },

      "& .sbri_logo": {
        maxWidth: 110,
        height: "auto",

        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(4),
        },
      },

      "& > *": {
        padding: theme.spacing(2),

        [theme.breakpoints.up("md")]: {
          padding: `0 ${theme.spacing(2)}px`,
        },
      },
    },
  })
);

export default useStyles;
