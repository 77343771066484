import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    about_us: {
      "& .home_hero": {
        "& .img_container": {
          maxHeight: 350,

          [theme.breakpoints.up("md")]: {
            height: 450,
          },

          "& img": {
            backgroundColor: "#2D0539",
          },
        },
      },
    },
    content: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    intro_container: {
      display: "flex",
      flexDirection: "column",

      "& .MuiTypography-h4": {
        marginBottom: theme.spacing(4),
      },
      "& .MuiTypography-body1:last-of-type": {
        marginTop: theme.spacing(2),
      },
      "& .MuiTypography-root": {
        textAlign: "justify",

        [theme.breakpoints.up("md")]: {
          textAlign: "initial",
        },
      },
    },
    suny_description: {
      display: "flex",
      flexDirection: "column",

      "& .title": {
        display: "flex",
        alignItems: "baseline",
        marginBottom: theme.spacing(3),

        "& .MuiTypography-h5": {
          marginLeft: theme.spacing(1),
          color: "#919090",
        },
      },

      "& > .MuiTypography-h5": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },

      "& > .MuiTypography-caption": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
      },
    },
    download_links: {
      display: "flex",
      alignItems: "center",

      "& img": {
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        height: "auto",
      },

      "& a": {
        maxWidth: 150,
      },

      "& a:last-of-type": {
        marginLeft: theme.spacing(3),
      },
    },
    meet_suny_img: {
      width: "100%",
      height: "auto",
    },
    orange: {
      color: "#FF8900",
    },
    dark_pink: {
      color: "#B92667",
    },
    cyan: {
      color: "#39A6AC",
    },
    green: {
      color: "#78A600",
    },
    ai_for_good_wrapper: {
      marginBottom: theme.spacing(3),
      height: 80,

      "& img": {
        height: "100%",
      },
    },
    achievement_item: {
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& svg": {
        // width: "100%",
        height: 170,
        alignSelf: "center",
      },

      "& .MuiTypography-h5": {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },

      "& .MuiTypography-body1": {
        marginTop: "auto",
      },
    },
    sign_up_container: {
      display: "flex",
      position: "relative",

      "& .description": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",

        "& .MuiButton-root": {
          marginTop: theme.spacing(3),
        },

        "& .MuiTypography-root": {
          color: theme.palette.common.white,
          textAlign: "center",

          [theme.breakpoints.up("md")]: {
            textAlign: "initial",
          },
        },
      },

      "& img": {
        width: "100%",
        minHeight: 350,
        objectFit: "cover",
      },
    },
    team_members_container: {
      justifyContent: "center",

      [theme.breakpoints.up("md")]: {
        justifyContent: "space-between",
      },
    },
    team_member_item: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      [theme.breakpoints.up("md")]: {
        alignItems: "flex-start",
      },

      "& img": {
        width: "100%",
        marginBottom: theme.spacing(3),
      },

      "& .social_container": {
        marginTop: theme.spacing(1),

        "& svg": {
          color: "#989898",
          width: 22,
          height: 22,
        },
        "& a:not(:first-of-type)": {
          marginLeft: theme.spacing(1),
        },
      },
    },
    venn_container: {
      width: "100%",
      "& img": {
        width: "100%",
        minHeight: 240,
        objectFit: "cover",
      },
    },
  })
);

export default useStyles;
