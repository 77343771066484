import React, { FC } from "react";

const UploadIcon: FC = () => {
  return (
    <svg
      id="surface1"
      xmlns="http://www.w3.org/2000/svg"
      width="98.645"
      height="108.431"
      viewBox="0 0 98.645 108.431"
    >
      <path
        id="Path_1923"
        data-name="Path 1923"
        d="M73.9,46.939V26.419a2.375,2.375,0,0,0-.655-1.559L50.238.7a2.293,2.293,0,0,0-1.65-.7H12.113A12.153,12.153,0,0,0,0,12.226V81.2A12.076,12.076,0,0,0,12.113,93.335H40.9A31.079,31.079,0,1,0,73.9,46.939ZM50.848,7.933,66.283,24.181H56.272a5.458,5.458,0,0,1-5.424-5.446ZM12.113,88.815A7.571,7.571,0,0,1,4.52,81.2V12.226A7.634,7.634,0,0,1,12.113,4.52H46.328V18.735A9.963,9.963,0,0,0,56.272,28.7H69.379v17.65c-.678-.022-1.22-.09-1.808-.09a31.533,31.533,0,0,0-20.61,7.775H18.26a2.26,2.26,0,1,0,0,4.519h24.5A35.955,35.955,0,0,0,38.78,65.56H18.26a2.26,2.26,0,1,0,0,4.52H37.311a31.016,31.016,0,0,0,1.356,18.757H12.113Zm55.436,15.119A26.554,26.554,0,1,1,94.1,77.379,26.582,26.582,0,0,1,67.549,103.934Zm0,0"
        transform="translate(0 0)"
        fill="#51ced2"
      />
      <path
        id="Path_1924"
        data-name="Path 1924"
        d="M77.811,206.2H100.7a2.26,2.26,0,1,0,0-4.52H77.811a2.26,2.26,0,1,0,0,4.52Zm0,0"
        transform="translate(-59.551 -158.971)"
        fill="#51ced2"
      />
      <path
        id="Path_1925"
        data-name="Path 1925"
        d="M262.709,283.934a2.243,2.243,0,0,0-3.3,0l-13.244,14.215a2.252,2.252,0,0,0,.113,3.187,2.307,2.307,0,0,0,3.231-.113l9.356-10.011v24.678a2.26,2.26,0,0,0,4.52,0V291.211l9.288,10.011a2.255,2.255,0,0,0,3.3-3.074Zm0,0"
        transform="translate(-193.555 -223.233)"
        fill="#51ced2"
      />
    </svg>
  );
};

export default UploadIcon;
